import { AtlasBotonesImagenes, AtlasImagenes, AtlasImagenesDetalles, Eventos, Imagenes, Sonidos, TexturasManuales, Variables } from "../Utilidades/Diccionario";
import { reproducirSonidoBotones } from "../Utilidades/exportFunction";
import { Idiomas, ObtenerTextoMultiIdioma, TextosAplicacion } from "../Utilidades/MultiIdioma";

export default class ConfiguracionesScene extends Phaser.Scene {
	public static Name = "ConfiguracionesScene";

	private fondoImagen: Phaser.GameObjects.Image;
	private cerrar: Phaser.GameObjects.Image;
	private textoIdioma: Phaser.GameObjects.Text;

	private musicaTexto: Phaser.GameObjects.Text;
	private musicaBoton: Phaser.GameObjects.Image;
	private musicaBotonMenos: Phaser.GameObjects.Image;
	private musicaBotonMas: Phaser.GameObjects.Image;
	private musicaTextoVolumen: Phaser.GameObjects.Text;
	private musicaVolumen: number;

	private musicaEfectosTexto: Phaser.GameObjects.Text;
	private musicaEfectosBoton: Phaser.GameObjects.Image;
	private musicaEfectosBotonMenos: Phaser.GameObjects.Image;
	private musicaEfectosBotonMas: Phaser.GameObjects.Image;
	private musicaEfectosTextoVolumen: Phaser.GameObjects.Text;
	private musicaEfectosVolumen: number;

	public init() {
		//
	}

	public preload(): void {
		//
	}

	public create(): void {
		this.add.image(0, 0, TexturasManuales.FondoTransparente)
			.setOrigin(0, 0);

		this.fondoImagen = this.add.image(0, 0, Imagenes.BackgroundModal)
			// .setVisible(false)
			.setDisplaySize(500, 300)
			.setOrigin(0, 0);

		this.musicaVolumen = <number> this.registry.get(Variables.MusicaVolumen);
		this.fondoImagen.setPosition(this.cameras.main.centerX - (this.fondoImagen.displayWidth / 2), this.cameras.main.centerY - (this.fondoImagen.displayHeight / 2));

		// .setDisplaySize(400, 280);
		// this.cerrar = this.add.image(this.fondoImagen.x + (this.fondoImagen.displayWidth / 2), this.fondoImagen.y - (this.fondoImagen.displayHeight / 2), Imagenes.Cerrar)
		this.cerrar = this.add.image(this.fondoImagen.x + this.fondoImagen.displayWidth, this.fondoImagen.y, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonCerrar))
			// .setOrigin(0, 0)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			})
			.on(Phaser.Input.Events.POINTER_UP, () => {
				reproducirSonidoBotones(this);
				this.registry.set(Variables.EscenaActual, this.registry.get(Variables.EscenaLaunchMenu));
				this.scene.resume(this.registry.get(Variables.EscenaLaunchMenu));
				this.scene.stop(ConfiguracionesScene.Name);
			});
		// this.crearObjetosIdiomas();
		this.crearObjetosVolumenMusica();
		this.crearObjetosVolumenEfectos();
		// this.crearObjetosEfectoGanador();
	}

	seleccionarIdioma(numeroIdioma: number) {
		reproducirSonidoBotones(this);
		this.registry.set(Variables.IdiomaSeleccionado, numeroIdioma);
		this.registry.events.emit(Eventos.CambiarIdioma, this.registry.get(Variables.EscenaLaunchMenu));
	}

	crearObjetosVolumenMusica() {
		const yPositionObjetos = this.fondoImagen.y + 40;
		const xPositionObjetos = this.fondoImagen.x + 30;
		this.musicaTexto = this.add.text(xPositionObjetos, yPositionObjetos, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.ConfiguracionScene_VolumenMusica))
			.setOrigin(0, 0)
			// .setAlign('center')
			.setFontFamily("Gobold-Bold")
			.setFontSize(32)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');

		const fnSonidoActivo = () => {
			const sonido = this.sound.get(Sonidos.MusicaFondo);
			return sonido?.isPlaying;
		};
		this.musicaBoton = this.add.image(xPositionObjetos + 150, yPositionObjetos + 20, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOn))
			.setDepth(2)
			// .setDisplaySize(40, 40)
			// .setOrigin(0, 0)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, () => {
				this.musicaBoton.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, () => {
				this.musicaBoton.clearTint();
			});
		this.musicaBoton.on(Phaser.Input.Events.POINTER_UP, () => {
			this.musicaBoton.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOn));
			const sonido = this.sound.get(Sonidos.MusicaFondo);
			this.musicaBoton.clearTint();
			if (!sonido) {
				this.sound.play(Sonidos.MusicaFondo, { loop: true, volume: this.musicaVolumen / 100 });
			}else if (sonido?.isPlaying) {
				sonido.stop();
				// this.musicaBoton.setTint(0xff504d);
				this.musicaBoton.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOff));
			} else {
				sonido.play({loop: true, volume: this.musicaVolumen / 100});
			}
		});
		if (!fnSonidoActivo()) {
			// this.musicaBoton.setTint(0xff504d);
			this.musicaBoton.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOff));
		}

		const callbackMusicaCambiarVolumen = (incrementar: boolean) => {
			if (this.musicaVolumen === 100 && incrementar) {
				return;
			} else if (this.musicaVolumen === 5 && !incrementar) {
				return;
			}

			this.musicaVolumen += (incrementar ? 5 : -5);
			this.musicaTextoVolumen.setText(`${this.musicaVolumen} %`);
			const sonido = this.sound.get(Sonidos.MusicaFondo);
			sonido.stop();
			sonido.play({loop: true, volume: this.musicaVolumen / 100});
			this.registry.set(Variables.MusicaVolumen, this.musicaVolumen);
		};
		this.musicaBotonMenos = this.add.image(this.musicaBoton.x + 100, this.musicaBoton.y, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.SeleccionCircularMenos))
			// .setDisplaySize(26, 20)
			// .setOrigin(0, 0)
			// .setRotation(1.5708)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				if (fnSonidoActivo()) {
					this.setTint(0x00ff00);
				}
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			})
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (fnSonidoActivo()) {
					reproducirSonidoBotones(this);
					callbackMusicaCambiarVolumen(false);
				}
			});

		this.musicaBotonMas = this.add.image(this.musicaBoton.x + 270, this.musicaBoton.y, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.SeleccionCircularMas))
			// .setDisplaySize(26, 20)
			// .setOrigin(0, 0)
			// .setRotation(1.5708)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				if (fnSonidoActivo()) {
					this.setTint(0x00ff00);
				}
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			})
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (fnSonidoActivo()) {
					reproducirSonidoBotones(this);
					callbackMusicaCambiarVolumen(true);
				}
			});

		// const sonido = this.sound.get(Sonidos.MusicaFondo);
		// if (!sonido) {
		// 	this.musicaVolumen = 100;
		// } else {
		// 	this.musicaVolumen = (sonido as any).config.volume * 100;
		// }
		this.musicaTextoVolumen = this.add.text(this.musicaBotonMenos.x + ((this.musicaBotonMas.x - this.musicaBotonMenos.x) / 2), this.musicaBoton.y, `${this.musicaVolumen} %`)
			// .setAlign('left')
			.setOrigin(0.5, 0.5)
			.setFontFamily("Gobold-Bold")
			.setFontSize(28)
			.setFontStyle('bold')
			.setStroke('#000000', 3)
			.setFill('#ffffff');

	}

	crearObjetosVolumenEfectos() {
		const yPositionObjetos = this.fondoImagen.y + 110;
		const xPositionObjetos = this.fondoImagen.x + 30;
		this.musicaEfectosTexto = this.add.text(xPositionObjetos, yPositionObjetos, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.ConfiguracionScene_VolumenEfectos))
			.setOrigin(0, 0)
			// .setAlign('center')
			.setFontFamily("Gobold-Bold")
			.setFontSize(32)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');

		const fnSonidoEfectosActivo = () => {
			return <boolean> this.registry.get(Variables.MusicaEfectosActivada);
		};

		this.musicaEfectosBoton = this.add.image(xPositionObjetos + 150, yPositionObjetos + 20, AtlasImagenes.Botones, (<boolean> this.registry.get(Variables.MusicaEfectosActivada) ? AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectos) : AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectosMute)))
			.setDepth(2)
			// .setDisplaySize(40, 40)
			// .setOrigin(0, 0)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, () => {
				this.musicaEfectosBoton.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, () => {
				this.musicaEfectosBoton.clearTint();
			});
		this.musicaEfectosBoton.on(Phaser.Input.Events.POINTER_UP, () => {
			const efectosSonidoActivados = <boolean> this.registry.get(Variables.MusicaEfectosActivada);
			this.registry.set(Variables.MusicaEfectosActivada, !efectosSonidoActivados);

			if (!efectosSonidoActivados) {
				this.musicaEfectosBoton.clearTint();
				this.musicaEfectosBoton.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectos));
			} else {
				// this.musicaEfectosBoton.setTint(0xff504d);
				this.musicaEfectosBoton.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectosMute));
			}
		});
		// if (!(<boolean> this.registry.get(Variables.MusicaEfectosActivada))) {
		// 	this.musicaEfectosBoton.setTint(0xff504d);
		// }

		const callbackMusicaEfectosCambiarVolumen = (incrementar: boolean) => {
			if (this.musicaEfectosVolumen === 100 && incrementar) {
				return;
			} else if (this.musicaEfectosVolumen === 5 && !incrementar) {
				return;
			}
			this.musicaEfectosVolumen += (incrementar ? 5 : -5);
			this.registry.set(Variables.MusicaEfectosVolumen, this.musicaEfectosVolumen);
			this.musicaEfectosTextoVolumen.setText(`${this.musicaEfectosVolumen} %`);
		};
		this.musicaEfectosBotonMenos = this.add.image(this.musicaEfectosBoton.x + 100 , this.musicaEfectosBoton.y, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.SeleccionCircularMenos))
			// .setDisplaySize(26, 20)
			// .setOrigin(0, 0)
			// .setRotation(1.5708)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				if (fnSonidoEfectosActivo()) {
					this.setTint(0x00ff00);
				}
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				if (fnSonidoEfectosActivo()) {
					this.clearTint();
				}
			})
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (fnSonidoEfectosActivo()) {
					callbackMusicaEfectosCambiarVolumen(false);
					reproducirSonidoBotones(this);
				}

			});

		this.musicaEfectosBotonMas = this.add.image(this.musicaEfectosBoton.x + 270, this.musicaEfectosBoton.y, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.SeleccionCircularMas))
			// .setDisplaySize(26, 20)
			// .setOrigin(0, 0)
			// .setRotation(1.5708)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				if (fnSonidoEfectosActivo()) {
					this.setTint(0x00ff00);
				}
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				if (fnSonidoEfectosActivo()) {
					this.clearTint();
				}
			})
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (fnSonidoEfectosActivo()) {
					callbackMusicaEfectosCambiarVolumen(true);
					reproducirSonidoBotones(this);
				}
			});

		this.musicaEfectosVolumen = <number> this.registry.get(Variables.MusicaEfectosVolumen);
		this.musicaEfectosTextoVolumen = this.add.text(this.musicaBotonMenos.x +  ((this.musicaEfectosBotonMas.x - this.musicaEfectosBotonMenos.x) / 2), yPositionObjetos + 10, `${this.musicaEfectosVolumen} %`)
			// .setAlign('left')
			.setFontFamily("Gobold-Bold")
			.setOrigin(0.5, 0.5)
			.setFontSize(28)
			.setFontStyle('bold')
			.setStroke('#000000', 3)
			.setFill('#ffffff');

	}

	cambioTextosIdioma() {
		this.textoIdioma.setText(ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.ConfiguracionScene_SeleccionarIdioma));
		this.musicaTexto.setText(ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.ConfiguracionScene_VolumenMusica));
		this.musicaEfectosTexto.setText(ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.ConfiguracionScene_VolumenEfectos));
	}
}
