export enum Idiomas {
	Espanol = 0,
	Ingles = 1,
	Portugues  = 2,
}

export enum TextosAplicacion {
	SeleccionarScene_BotonComenzar = "SeleccionarScene_BotonComenzar",
	SeleccionarScene_BotonAleatorio = "SeleccionarScene_BotonAleatorio",
	SeleccionarScene_TextoSeleccione = "SeleccionarScene_TextoSeleccione",
	SeleccionRapidaScene_BotonAleatorio = "SeleccionRapidaScene_BotonAleatorio",
	SeleccionRapidaScene_SusNumeros = "SeleccionRapidaScene_SusNumeros",
	FooterScene_TextoSaldo = "FooterScene_TextoSaldo",
	MainGameScene_BotonLanzar = "MainGameScene_BotonLanzar",
	MainGameScene_ApuestaPorLinea = "MainGameScene_ApuestaPorLinea",
	MainGameScene_CantidadLineas = "MainGameScene_CantidadLineas",
	ConfiguracionScene_SeleccionarIdioma = "ConfiguracionScene_SeleccionarIdioma",
	ConfiguracionScene_VolumenMusica = "ConfiguracionScene_VolumenMusica",
	ConfiguracionScene_VolumenEfectos = "ConfiguracionScene_VolumenMusicaEfectos",
	AyudaScene_Ayuda1Titulo = "AyudaScene_Ayuda1Titulo",
	AyudaScene_Ayuda1Detalle = "AyudaScene_Ayuda1Detalle",
	AyudaScene_Ayuda2Titulo = "AyudaScene_Ayuda2Titulo",
	AyudaScene_Ayuda2Detalle = "AyudaScene_Ayuda2Detalle",
	AyudaScene_Ayuda3Titulo = "AyudaScene_Ayuda3Titulo",
	AyudaScene_Ayuda3Detalle = "AyudaScene_Ayuda3Detalle",
}

export const TextosMultidioma: {[Alias: string]: {TextosMulti: string[] }} = {
	'SeleccionarScene_BotonComenzar': { TextosMulti: ['Comenzar', 'Start', 'Começar'] },
	'SeleccionarScene_BotonAleatorio': { TextosMulti: ['Aleatorio', 'Random', 'Aleatório'] },
	'SeleccionarScene_BotonSeleccionRapida': { TextosMulti: ['Rapida', 'Fast', 'Velozes'] },
	'SeleccionarScene_TextoSeleccione': { TextosMulti: ['Seleccione sus numeros', 'Select your numbers', 'Selecione seus números'] },
	'SeleccionRapidaScene_BotonAleatorio': { TextosMulti: ['Azar', 'Random', 'Aleatório'] },
	'SeleccionRapidaScene_SusNumeros': { TextosMulti: ['TUS NÚMEROS', 'Your Numbers', 'Seus números'] },
	'FooterScene_TextoSaldo': { TextosMulti: ['Saldo', 'Balance', 'Equilíbrio'] },
	'MainGameScene_BotonLanzar': { TextosMulti: ['Lanzar', 'Throw', 'Lançar'] },
	'MainGameScene_ApuestaPorLinea': { TextosMulti: ['Por Línea', 'Per Line', 'Por Linha'] },
	'MainGameScene_CantidadLineas': { TextosMulti: ['Líneas', 'Lines', 'Linhas'] },
	'ConfiguracionScene_SeleccionarIdioma': { TextosMulti: ['Idioma', 'Language', 'Idioma'] },
	'ConfiguracionScene_VolumenMusica': { TextosMulti: ['Música', 'Music', 'Música'] },
	'ConfiguracionScene_VolumenMusicaEfectos': { TextosMulti: ['Efectos', 'Effects', 'Efeitos'] },
	'AyudaScene_Ayuda1Titulo': { TextosMulti: ['¿Cómo jugar?', 'How to play', 'Como jogar'] },
	'AyudaScene_Ayuda1Detalle': { TextosMulti: [
		`Para jugar debes elegir, para cada uno de los 
juegos, un total de 3 monedas. Puedes elegir cada 
moneda manualmente o seleccionar “mostrar todo” 
para elegir las 12 monedas de forma aleatoria. 
El universo de monedas existentes es de 15, 
por lo que siempre quedarán 3 monedas sin elegir. 
Y cada juego es independiente del otro.`, // -------------------
		`Select 6 numbers from 1 to 41.

		If 2 or more of these numbers appear on a line you win.

		Enter the number of lines you want to play
		and your money bet per line.`, // -------------------
		`Selecione 6 números de 1 a 41.

		Se 2 ou mais desses números aparecerem em uma linha, você ganha.

		Selecione um número de estrela de 1 a 15
		, se sua estrela aparecer você ganhará um bônus.

		Digite o número de linhas que você deseja jogar
		e seu dinheiro apostado por linha.`] },
	'AyudaScene_Ayuda2Titulo': { TextosMulti: ['¿Cómo jugar?', 'Lines', 'Linhas'] },
	'AyudaScene_Ayuda2Detalle': { TextosMulti: [
		`La jugada en total vale $400 y en total tendrás 4 
opciones de ganar. El valor de cada juego es de 
$100 y, como son 4 en total, hace que el monto a 
pagar sea $400.`, // -------------------
		`You can win up to 10 lines. Prizes are for bet per line.

		Your selected star multiplies the winning lines
		where the final ball of that line is located, so each star
		 can power multiple lines. invented text

		With the controls located at the bottom,
		select the lines you want to play and your bet per line.

		`, // -------------------
		`Você pode ganhar até 10 linhas. Os prêmios são para aposta por linha.

		Sua estrela selecionada multiplica as linhas vencedoras
		onde está localizada a última bola dessa linha, de modo que cada estrela
		 pode alimentar várias linhas. texto inventado

		Com os controles localizados na parte inferior,
		selecione as linhas que deseja jogar e sua aposta por linha.

		`]
	},
	'AyudaScene_Ayuda3Titulo': { TextosMulti: ['¿Cómo jugar?', 'Wins and Bonus', 'Linhas'] },
	'AyudaScene_Ayuda3Detalle': { TextosMulti: [
		`Si la suma de las 3 monedas elegidas en un solo 
juego suma 7, 14, 21 o 28 ganas el premio que se 
indica. Si ganas en más de un juego, el premio 
será la suma de los juegos ganadores.`,// -------------------
		`If a number is repeated it is still a winning number.
If your numbers are 1,2,3,4 and 5 and you see 1, 1, 1, 1, 1 you will still win x1000
Your selected star + 5 numbers = x10,000`,// -------------------
		`Se um número for repetido, ainda é um número vencedor.
Se seus números forem 1,2,3,4 e 5 e você vir 1, 1, 1, 1, 1 você ainda ganhará x1000
Sua estrela selecionada + 5 números = x10.000`
	] },
}

export function ObtenerTextoMultiIdioma(idioma: Idiomas, tipoTexto: TextosAplicacion) {
	return TextosMultidioma[tipoTexto].TextosMulti[idioma];
}
