import moment from "moment";
import { AtlasImagenes, AtlasImagenesVarias, Eventos, Imagenes, Variables, manejarDepthMainGame } from "../Utilidades/Diccionario";
import { esDispositivoMovil } from "../Utilidades/exportFunction";
import { ParametrosEntrada } from "../Utilidades/Intefaces";
import { ObtenerTextoMultiIdioma, TextosAplicacion } from "../Utilidades/MultiIdioma";

export default class FooterScene extends Phaser.Scene {
	public static Name = "FooterScene";
	public horaTituloTexto: Phaser.GameObjects.Text;
	public horaTexto: Phaser.GameObjects.Text;
	public saldoTexto: Phaser.GameObjects.Text;
	public eventoTiempo: Phaser.Time.TimerEvent;
	private imgSaldo: Phaser.GameObjects.Image;
	private imgTiempo: Phaser.GameObjects.Image;

	private horaEntrada: Date;

	// public costoPorLanzar: Phaser.GameObjects.Text;
	public init() {
		// por implementar
		// this.scene.moveDown();
	}

	public preload(): void {
		// por implementar

	}

	public create(): void {
		this.horaEntrada = <Date> this.registry.get(Variables.FechaHoraInicio);
		const graphics = this.add.graphics({ fillStyle: { color: 0x9b9e9c } }).setAlpha(0.1);
		const rect1 = new Phaser.Geom.Rectangle(0, this.cameras.main.height - 90, this.cameras.main.width, 80);
		graphics.fillRectShape(rect1);

		this.crearTiempo();
		this.crearSaldo();

		this.eventoTiempo = this.time.addEvent({
			delay: 1000,
			repeat: -1,
			callback: () => {
				this.refrescarHora();
			}
		});

		this.registry.events.removeListener(Eventos.CambiosSaldoCliente);
		this.registry.events.on(Eventos.CambiosSaldoCliente, () => {
			console.log("-------------------------Refresh Balance");
			window.parent.postMessage("refreshBalance", "*");
			const textoSaldo = ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.FooterScene_TextoSaldo);
			const nuevoSaldo = <number> this.registry.get(Variables.Saldo);
			this.saldoTexto.setText(`${textoSaldo}: ${nuevoSaldo.toLocaleString('es-cl').padStart(7, " ")}`)
		});

		this.add.image(this.cameras.main.width - 200, this.cameras.main.height - 40, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.Polla)
		// this.add.image(1200, 640, Imagenes.Polla)
			.setOrigin(0, 0.5)
			// .setDisplaySize(155, 45)
			.setDepth(manejarDepthMainGame.profundidad3)
		const parametrosEntrada = <ParametrosEntrada> this.registry.get(Variables.ParametrosEntrada);
		this.mostrarTiempo(parametrosEntrada.channel != "3" || this.scale.isFullscreen || esDispositivoMovil());
	}

	private crearTiempo() {
		this.imgTiempo = this.add.image(450, this.cameras.main.height - 80, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.Tiempo)
			.setOrigin(0, 0)
			.setDepth(manejarDepthMainGame.profundidad3);
		this.horaTituloTexto = this.add.text(0, 0, `Tiempo: `)
			.setOrigin(0.5, 0)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setFontFamily('Gobold-Bold')
			.setFontSize(26)
			.setFill('#ffffff');
		Phaser.Display.Align.In.LeftCenter(this.horaTituloTexto, this.imgTiempo, -25);

		this.horaTexto = this.add.text(0, 0, ``)
			.setOrigin(0.5, 0)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setFontFamily('Gobold-Bold')
			.setFontSize(26)
			.setFill('#ffffff');
		Phaser.Display.Align.In.Center(this.horaTexto, this.imgTiempo, 20);
	}

	private crearSaldo() {
		this.imgSaldo = this.add.image(40, this.cameras.main.height - 80, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.Saldo)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setOrigin(0, 0);
		const saldo = <number>this.registry.get(Variables.Saldo);
		const textoSaldo = ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.FooterScene_TextoSaldo);
		this.saldoTexto = this.add.text(0, 0, `${textoSaldo}: ${saldo.toLocaleString('es-cl').padStart(7, " ")}`)
			.setOrigin(0.5, 0)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setFontFamily("Gobold-Bold")
			.setFontSize(26)
			.setFill("#ffffff");
		Phaser.Display.Align.In.LeftCenter(this.saldoTexto, this.imgSaldo, -25);
		window.parent.postMessage("refreshBalance", "*");
	}

	refrescarHora() {
		const tiempo = moment.duration(moment().diff(moment(this.horaEntrada)));
		if (tiempo.asHours() >= 1) {
			this.horaTexto.setText(`${moment.utc(tiempo.as('milliseconds')).format('HH:mm:ss')}`);
		} else {
			this.horaTexto.setText(`${moment.utc(tiempo.as('milliseconds')).format('mm:ss')}`);
		}
	}

	mostrarTiempo(mostrar: boolean) {
		this.imgTiempo?.setVisible(mostrar);
		this.horaTituloTexto?.setVisible(mostrar);
		this.horaTexto?.setVisible(mostrar);
	}

	cambioTextosIdioma() {
		const saldo = <number> this.registry.get(Variables.Saldo);
		const textoSaldo = ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.FooterScene_TextoSaldo);
		this.saldoTexto.setText(`${textoSaldo}: ${saldo.toLocaleString('es-cl')}`);
	}

}