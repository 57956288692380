import 'phaser';
import Preloader from "./Scenes/Preloader";
import SplashScreen from "./Scenes/SplashScreen";
import MenuScene from "./Scenes/MenuScene";
import FooterScene from "./Scenes/FooterScene";
import AyudaScene from "./Scenes/AyudaScene";
import ConfiguracionesScene from "./Scenes/ConfiguracionesScene";
import PrincipalScene from "./Scenes/PrincipalScene";
import ErrorParametrosScene from './Scenes/ErrorParametrosScene';
import MensajePopupReintentarScene from './Scenes/MensajePopupReintentarScene';
import { configuracion } from "./Config/config";

const gameConfig: Phaser.Types.Core.GameConfig = {
	// width: 1650,
	// height: 800,
	width: 2048,
	height: 1152,
	type: Phaser.AUTO,
	//pixelArt: true,
	backgroundColor: '#1E3915',
	parent: "phaser-game",
	title: "Demo 7 de la Suerte",
	maxLights: 5,
	// physics: {
	// 	default: 'arcade',
	// 	arcade: {
	// 		gravity: {
	// 			y: 800
	// 		},
	// 		debug: true
	// 	}
	// },
	scale: {
		mode: Phaser.Scale.ScaleModes.FIT,
		autoCenter: Phaser.Scale.Center.CENTER_BOTH
	},
	banner: false,
	// banner: {
	// 	hidePhaser: true,
	// 	text: '#fff00f',
	// 	background: [
	// 		'#16a085',
	// 		'#2ecc71',
	// 		'#e74c3c',
	// 		'#000000',
	// 	]
	// },
	// scene: [Preloader, SplashScreen, MainGame]
};

export default class Game extends Phaser.Game {
	constructor(config: Phaser.Types.Core.GameConfig) {

		super(config);

		this.scene.add(Preloader.Name, Preloader);
		this.scene.add(SplashScreen.Name, SplashScreen);
		this.scene.add(PrincipalScene.Name, PrincipalScene);
		this.scene.add(MenuScene.Name, MenuScene);
		this.scene.add(FooterScene.Name, FooterScene);
		this.scene.add(AyudaScene.Name, AyudaScene);
		this.scene.add(ConfiguracionesScene.Name, ConfiguracionesScene);
		this.scene.add(ErrorParametrosScene.Name, ErrorParametrosScene);
		this.scene.add(MensajePopupReintentarScene.Name, MensajePopupReintentarScene);
		this.scene.start(Preloader.Name);
		console.log(`version ${configuracion.version}`);
	}
}

/**
 * Workaround for inability to scale in Phaser 3.
 * From http://www.emanueleferonato.com/2018/02/16/how-to-scale-your-html5-games-if-your-framework-does-not-feature-a-scale-manager-or-if-you-do-not-use-any-framework/
 */
function resize(): void {
	const canvas = document.querySelector("canvas");
	const width = window.innerWidth;
	const height = window.innerHeight;
	const wratio = width / height;
	const ratio = Number(gameConfig.width) / Number(gameConfig.height);
	if (wratio < ratio) {
		canvas.style.width = width + "px";
		canvas.style.height = (width / ratio) + "px";
	} else {
		canvas.style.width = (height * ratio) + "px";
		canvas.style.height = height + "px";
	}
}

window.onload = (): void => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const game = new Game(gameConfig);
	// Uncomment the following two lines if you want the game to scale to fill the entire page, but keep the game ratio.
	//resize();
	//window.addEventListener("resize", resize, true);
	loadFont("Gobold-Bold", "assets/font/Gobold-Bold.woff2");
	getParametros();
};

function loadFont(name, url) {
	const newFont = new FontFace(name, `url(${url})`);
	newFont.load().then(function (loaded) {
		document.fonts.add(loaded);
	}).catch(function (error) {
		return error;
	});
}

function getParametros() {
	const url = new URL(document.URL);
	const searchParams = new URLSearchParams(url.search);
	const channel = searchParams.get('channel') || '3';
	const sessionToken = searchParams.get('sessionToken');
	const mode = searchParams.get('mode') || 'NORMAL';
	const lobbyUrl = searchParams.get('lobbyUrl') || '';
	localStorage.setItem('param', JSON.stringify({ channel, sessionToken, mode, lobbyUrl }));
}
