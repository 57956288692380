import { Sonidos, Variables } from "./Diccionario";

/* eslint-disable no-mixed-spaces-and-tabs */
export function numerosAzar(ordenados = false) {
	const listaNumeros: number[] = [];
	while (listaNumeros.length <= 5) {
		const numeroAzar = Phaser.Math.Between(1,41);
		if (!listaNumeros.some(x => x === numeroAzar)) {
			listaNumeros.push(numeroAzar);
		}
	}
	if (ordenados) {
		listaNumeros.sort((a, b) => a - b)
	}
	return listaNumeros;
}

//TODO: ESTA FUNCION NO DEBERIA ESTAR EN LA VERSION FINAL YA QUE VIENE DEL BACKEND
export function numerosSorteadosAzar() {
	const listaNumeros: number[] = [1, 2, 4];
	while (listaNumeros.length <= 11) {
		const numeroAzar = Phaser.Math.Between(1,15);
		if (!listaNumeros.some(x => x === numeroAzar)) {
			listaNumeros.push(numeroAzar);
		}
	}
	// if (ordenados) {
	// 	listaNumeros.sort((a, b) => a - b);
	// }
	return listaNumeros;
}

export function fragmentShaderFuegosArtificiales(cantidadFuegos = 3, cantidadFragmentacion = 5) {
 	return `
	precision highp float;   /// iOS needs high?
	uniform float time;
	uniform vec2 resolution;
	varying vec2 fragCoord;
	// "[SH17A] Fireworks" by Martijn Steinrucken aka BigWings/Countfrolic - 2017
	// License Creative Commons Attribution-NonCommercial-ShareAlike 3.0 Unported License.
	// Based on https://www.shadertoy.com/view/lscGRl
	#define N(h) fract(sin(vec4(6,9,1,0)*h) * 9e2)
	void main(void)
	{
	  vec4 o;
	  vec2 u = fragCoord.xy/resolution.y;
	// proper pixelate
	float s = 500.;
		u = floor(u * s) / s;
	  float e, d, i=0.;
	  vec4 p;
	  for(float i=1.; i<${cantidadFuegos}.; i++) {
		d = floor(e = i*9.1+time);
		p = N(d)+.3;
		e -= d;
		for(float d=0.; d<${cantidadFragmentacion}.;d++)
		  o += p*(2.9-e)/1e3/length(u-(p-e*(N(d*i)-.5)).xy);
	  }
	  gl_FragColor = vec4(o.rgb, 1.0);
	}
	`;
}

export function fragmentShaderFuego() {
	return `
	#ifdef GL_ES
	precision mediump float;
	#endif

	// Yuldashev Mahmud Effect took from shaderToy mahmud9935@gmail.com

	uniform float time;
	uniform vec2 mouse;
	uniform vec2 resolution;

	float snoise(vec3 uv, float res)
	{
		const vec3 s = vec3(1e0, 1e2, 1e3);

		uv *= res;

		vec3 uv0 = floor(mod(uv, res))*s;
		vec3 uv1 = floor(mod(uv+vec3(1.), res))*s;

		vec3 f = fract(uv); f = f*f*(3.0-2.0*f);

		vec4 v = vec4(uv0.x+uv0.y+uv0.z, uv1.x+uv0.y+uv0.z,
					uv0.x+uv1.y+uv0.z, uv1.x+uv1.y+uv0.z);

		vec4 r = fract(sin(v*1e-1)*1e3);
		float r0 = mix(mix(r.x, r.y, f.x), mix(r.z, r.w, f.x), f.y);

		r = fract(sin((v + uv1.z - uv0.z)*1e-1)*1e3);
		float r1 = mix(mix(r.x, r.y, f.x), mix(r.z, r.w, f.x), f.y);

		return mix(r0, r1, f.z)*2.-1.;
	}

	void main( void ) {

		vec2 p = -.5 + gl_FragCoord.xy / resolution.xy;
		p.x *= resolution.x/resolution.y;

		float color = 3.0 - (3.*length(2.*p));

		vec3 coord = vec3(atan(p.x,p.y)/6.2832+.5, length(p)*.4, .5);

		for(int i = 1; i <= 7; i++)
		{
			float power = pow(2.0, float(i));
			color += (1.5 / power) * snoise(coord + vec3(0.,-time*.05, time*.01), power*16.);
		}
		gl_FragColor = vec4( color, pow(max(color,0.),1.)*0.4, pow(max(color,0.),2.)*0.15 , color);
	}
	`;
}

export function fragmentShaderRGBShiftField() {
	return `
	precision highp float;

	uniform float time;
	uniform vec2 resolution;

	#define iTime time
	#define iResolution resolution

	//modified version of https://www.shadertoy.com/view/4ljXDt

	float Cell(vec2 c) {
		vec2 uv = fract(c);c -= uv;
		return (1.-length(uv*2.-1.)) * step(fract(sin(c.x+c.y*1e2)*1e3), .04);
	}

	void mainImage( out vec4 fragColor, in vec2 fragCoord )
	{
		vec2 p = fragCoord.xy / iResolution.xy -.5;
		float a = fract(atan(p.x, p.y) / 6.2832);
		float d = length(p);
		float z = iTime / 1.5;
		vec3 col;

		for(int i=0; i<3 ;i++)
		{
			z += 0.02;
			vec2 coord = vec2(pow(d, .04), a)*256.;
			vec2 delta = vec2(1. + z*20., 1.);
			float c = Cell(coord-=delta);
			c += Cell(coord-=delta);
			col[i]=c*d*3.;
		}

		fragColor = vec4(col,1);
	}

	void main(void)
	{
		mainImage(gl_FragColor, gl_FragCoord.xy);
	}
	`;
}

export function fragmentShaderLayerStarfield() {
	return `
	precision highp float;

	uniform float time;
	uniform vec2 resolution;

	#define iTime time
	#define iResolution resolution

	/*
		Improved Layer Starfield
		Credits:    https://www.shadertoy.com/view/4djSRW
					https://www.shadertoy.com/view/lscczl
	*/
	#define R iResolution.xy
	#define T iTime

	mat2 rotate(float a) {
		float c = cos(a);
		float s = sin(a);
		return mat2(c, s, -s, c);
	}

	// one dimensional | 1 in 1 out
	float hash11(float p) {
		p = fract(p * 35.35);
		p += dot(p, p + 45.85);
		return fract(p * 7858.58);
	}

	// two dimensional | 2 in 1 out
	float hash21(vec2 p) {
		p = fract(p * vec2(451.45, 231.95));
		p += dot(p, p + 78.78);
		return fract(p.x * p.y);
	}

	// two dimensional | 2 in 2 out
	vec2 hash22(vec2 p) {
		vec3 q = fract(p.xyx * vec3(451.45, 231.95, 7878.5));
		q += dot(q, q + 78.78);
		return fract(q.xz * q.y);
	}

	float layer(vec2 uv) {

		float c = 0.;

		uv *= 15.;

		// id and coordinates per cell
		// f -> [-1, 1] to allow more size and glow variations
		// tf: stop the neighbour cells "cutting off" star glow
		vec2 i = floor(uv);
		vec2 f = 2. * fract(uv) - 1.;

		// random position for the star in the cell
		vec2 p = .3 * hash22(i);
		float d = length(f - p);

		// create fuzzier stars with random radius
		// col * (1. / d) -> glow
		c += smoothstep(.1 + .8 * hash21(i), .01, d);
		c *= (1. / d) * .2;

		return c;
	}

	vec3 render(vec2 uv) {

		vec3 col = vec3(0.);

		// rotate the whole scene
		uv *= rotate(T * .1);

		// oscillation to add more variations
		uv += 2. * vec2(cos(T * .001), sin(T * .001));

		// num layers - increase for more stars
		// adjust based on your machine
		const float num = 20.;
		const float inc = 1. / num;

		for (float i = 0.; i < 1.; i += inc) {

			// random rotate - stop repeating stars in consequent layers
			uv *= rotate(hash11(i) * 6.28);

			// i mapped to t -> [0, 1]
			float t = fract(i - T * .05);

			// smoothstep is useful for scaling and fading
			float s = smoothstep(.001, .95, t); // z-position of layer
			float f = smoothstep(0., 1., t); // fade per layer
			f *= smoothstep(1., 0., t);

			// random offset per layer - gives each layer the
			// appearance of drifiting
			vec2 k = .1 * hash22(vec2(i, i * 5.));
			float l = layer((uv - k) * s);

			// mix bg and fg colors
			col += mix(vec3(.03, .01, .04), vec3(.9, .4, 0.), l) * f;

		}

		// optional - just some subtle noise on top
		col += .02 * hash21(uv + T * .001);
		return col;

	}

	void mainImage(out vec4 O, in vec2 I) {
		vec2 uv = (2. * I - R) / R.y;
		vec3 color = render(uv);
		O = vec4(color, 1.);
	}

	void main(void)
	{
		mainImage(gl_FragColor, gl_FragCoord.xy);
	}
	`;
}

export function configuracionLineasGanadoras() {
	return [
		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 250, posicionDerechaY: 250, color: 0xed7e2d, pixelesCorreccion: 0, listaBolas: [0, 1, 2, 3, 4, 5 ] },
		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 375, posicionDerechaY: 375, color: 0xed7e2d, pixelesCorreccion: 0,listaBolas: [6, 7, 8, 9, 10, 11] },
		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 500, posicionDerechaY: 500, color: 0xed7e2d, pixelesCorreccion: 0,listaBolas: [12, 13, 14, 15, 16, 17] },

		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 220, posicionDerechaY: 470, color: 0x92d14f, pixelesCorreccion: -15, listaBolas: [0, 1, 8, 9, 16, 17] },
		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 345, posicionDerechaY: 220, color: 0x92d14f, pixelesCorreccion: -15, listaBolas: [6, 7, 14, 15, 4, 5] },
		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 470, posicionDerechaY: 345, color: 0x92d14f, pixelesCorreccion: -15, listaBolas: [12, 13, 2, 3, 10, 11] },

		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 280, posicionDerechaY: 280, color: 0xa3c1db, pixelesCorreccion: 15, listaBolas: [0, 13, 14, 9, 10, 5] },
		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 405, posicionDerechaY: 405, color: 0xa3c1db, pixelesCorreccion: 15, listaBolas: [6, 1, 2, 15, 16, 11] },
		{ posIzquierdaX: 290, posDerechaX: 1080, posicionIzquierdaY: 530, posicionDerechaY: 530, color: 0xa3c1db, pixelesCorreccion: 15, listaBolas: [12, 7, 8, 3, 4, 17] },

		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 155, posicionDerechaY: 155, color: 0xed7e2d, pixelesCorreccion: 0, listaBolas: [0, 1, 2, 3, 4, 5 ] },
		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 255, posicionDerechaY: 255, color: 0xed7e2d, pixelesCorreccion: 0,listaBolas: [6, 7, 8, 9, 10, 11] },
		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 355, posicionDerechaY: 355, color: 0xed7e2d, pixelesCorreccion: 0,listaBolas: [12, 13, 14, 15, 16, 17] },

		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 130, posicionDerechaY: 330, color: 0x92d14f, pixelesCorreccion: -15, listaBolas: [0, 1, 8, 9, 16, 17] },
		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 230, posicionDerechaY: 130, color: 0x92d14f, pixelesCorreccion: -15, listaBolas: [6, 7, 14, 15, 4, 5] },
		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 330, posicionDerechaY: 230, color: 0x92d14f, pixelesCorreccion: -15, listaBolas: [12, 13, 2, 3, 10, 11] },

		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 180, posicionDerechaY: 180, color: 0xa3c1db, pixelesCorreccion: 15, listaBolas: [0, 13, 14, 9, 10, 5] },
		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 280, posicionDerechaY: 280, color: 0xa3c1db, pixelesCorreccion: 15, listaBolas: [6, 1, 2, 15, 16, 11] },
		// { posIzquierdaX: 25, posDerechaX: 660, posicionIzquierdaY: 380, posicionDerechaY: 380, color: 0xa3c1db, pixelesCorreccion: 15, listaBolas: [12, 7, 8, 3, 4, 17] },

	];
}

export function reproducirSonidoBotones(escena: Phaser.Scene) {
	const efectos = <boolean> escena.registry.get(Variables.MusicaEfectosActivada);
	if (efectos) {
		const volumen = <number> escena.registry.get(Variables.MusicaEfectosVolumen);
		escena.sound.play(Sonidos.ApretarBoton, {volume: volumen / 100});
	}
}

export function reproducirSonidoCambioEscena(escena: Phaser.Scene) {
	const efectos = <boolean> escena.registry.get(Variables.MusicaEfectosActivada);
	if (efectos) {
		const volumen = <number> escena.registry.get(Variables.MusicaEfectosVolumen);
		escena.sound.play(Sonidos.CambioEscena, {volume: volumen / 100});
	}
}

export function reproducirSonidoLanzarTombola(escena: Phaser.Scene, comenzar: boolean) {
	const sonido = escena.sound.get(Sonidos.Spin);
	if (!comenzar) {
		if (sonido?.isPlaying) {
			sonido.stop();
		}
		return;
	}

	const efectos = <boolean> escena.registry.get(Variables.MusicaEfectosActivada);
	if (efectos) {
		if (!sonido) {
			const volumen = <number> escena.registry.get(Variables.MusicaEfectosVolumen);
			escena.sound.play(Sonidos.Spin, {volume: volumen / 100, loop: true});
		} else if (!sonido.isPlaying) {
			sonido.play({loop: true});
		}
	}
}

export function reproducirSonidoWin(escena: Phaser.Scene) {
	const efectos = <boolean> escena.registry.get(Variables.MusicaEfectosActivada);
	if (efectos) {
		const volumen = <number> escena.registry.get(Variables.MusicaEfectosVolumen);
		escena.sound.play(Sonidos.Win, {volume: volumen / 100});
	}
}

export function reproducirSonidoNuevoSorteo(escena: Phaser.Scene) {
	const efectos = <boolean> escena.registry.get(Variables.MusicaEfectosActivada);
	if (efectos) {
		const volumen = <number> escena.registry.get(Variables.MusicaEfectosVolumen);
		escena.sound.play(Sonidos.NuevoSorteo, {volume: volumen / 100});
	}
}

export function reproducirSonidoTerminarMostrarNumero(escena: Phaser.Scene) {
	const efectos = <boolean> escena.registry.get(Variables.MusicaEfectosActivada);
	if (efectos) {
		const volumen = <number> escena.registry.get(Variables.MusicaEfectosVolumen);
		escena.sound.play(Sonidos.MostrarNumero, {volume: volumen / 100});
	}
}

export function esDispositivoiOS(){
	// const userAgent = window.navigator.userAgent;
	// return (/iP(hone|od|ad)/.test(userAgent));
	return !!navigator.platform.match(/iPhone|iPod|iPad/);

}

export function esDispositivoMovil() {
	return (!!navigator.userAgent.match(/Android/i) ||
			!!navigator.userAgent.match(/webOS/i) ||
			!!navigator.userAgent.match(/iPhone/i) ||
			!!navigator.userAgent.match(/iPad/i) ||
			!!navigator.userAgent.match(/iPod/i) ||
			!!navigator.userAgent.match(/BlackBerry/i) ||
			!!navigator.userAgent.match(/Windows Phone/i));
}

