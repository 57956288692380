import { AtlasAnimaciones, AtlasAnimacionesDetalles, AtlasBotonesImagenes, AtlasImagenes, AtlasImagenesDetalles, AtlasImagenesVarias, Eventos, manejarDepthMainGame } from "../Utilidades/Diccionario";
import { IObjeto3D } from "../Utilidades/Intefaces";

export class MonedaComponent implements IObjeto3D {
	private monedaSprite: Phaser.GameObjects.Sprite;
	private numeroSprite: Phaser.GameObjects.Sprite;
	private numeroTexto: Phaser.GameObjects.Text;
	private estrellasAnimSprite: Phaser.GameObjects.Sprite;
	private monedaPulsada: boolean;
	private manejarDepth = {
		Fondo: 10,
		TituloJuego: 15,
		Estrella: 20,
		Simbolos: 40,
		CuadroGanador: 30,
	};
	constructor(private escena: Phaser.Scene, private x: number, private y: number, private callbackVerificarLibre: () => boolean ) {
		this.crear();
	}

	get MonedaPulsada() {
		return this.monedaPulsada;
	}

	girarStartObjeto() {
		const config: Phaser.Types.GameObjects.Particles.ParticleEmitterConfig = {
			frame: AtlasImagenesDetalles[AtlasImagenes.Moneda3D].callbackObtenerFrame("1"),
			lifespan: 1000,
			speed: { min: 300, max: 400 },
			alpha: { start: 1, end: 0 },
			scale: { start: 0.5, end: 0 },
			rotate: { start: 0, end: 360, ease: 'Power2' },
			blendMode: 'ADD',
			frequency: -1
		};
		const particlesEmitterExplosion = this.escena.add.particles(null, null, AtlasImagenes.Moneda3D, config)
			.setDepth(manejarDepthMainGame.profundidad7);
		particlesEmitterExplosion.explode(8, this.x, this.y);

		this.numeroSprite?.destroy();
		this.numeroTexto?.destroy();
		this.monedaSprite?.destroy();
		this.crear();
		this.monedaSprite.anims.play({
			key: AtlasAnimacionesDetalles[AtlasAnimaciones.Moneda3D].NombreAnimacion,
			// frameRate: Phaser.Math.Between(6, 20)
			frameRate: 12
		}, true);
	}

	terminarPulsacion(numeroSorteado: number) {
		this.monedaPulsada = true;
		// this.monedaSprite.anims.stopOnFrame(this.monedaSprite.anims.currentAnim.frames[0]);
		this.monedaSprite.destroy();
		// this.monedaSprite.anims.stop();
		// this.monedaSprite.setVisible(false);
		// this.monedaSprite.setDisplaySize(160, 160);
		this.numeroSprite = this.escena.add.sprite(this.x, this.y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.NumeroSorteado)
			.setAlpha(0)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setDisplaySize(135, 135);
		this.numeroTexto = this.escena.add.text(this.x, this.y, `${numeroSorteado}`)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setAlpha(0)
			.setFontFamily("Gobold-Bold")
			// .setFontStyle("bold")
			.setFontSize(48)
			.setFill("#ffffff");
		Phaser.Display.Align.In.Center(this.numeroTexto, this.numeroSprite);

		this.estrellasAnimSprite.setVisible(true);
		this.estrellasAnimSprite.anims.play({
			key: AtlasAnimacionesDetalles[AtlasAnimaciones.Estrellas].NombreAnimacion,
			// frameRate: Phaser.Math.Between(6, 20)
			frameRate: 10,
			repeat: 0,
			hideOnComplete: true
		}, true);

		this.escena.tweens.add({
			targets: [this.numeroSprite, this.numeroTexto],
			duration: 1000,
			alpha: 1,
			onStart: () => {
				//
			},
			onComplete: () => {
				//
			}
		});
	}

	habilitarPulsacion() {
		this.monedaSprite.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				//
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				//
			})
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (this.callbackVerificarLibre()) {
					this.escena.registry.events.emit(Eventos.PulsarMoneda, this);
				}
			});
	}

	obtenerSprite() {
		return this.monedaSprite;
	}

	private crear() {
		this.monedaPulsada = false;
		this.monedaSprite = this.escena.add.sprite(this.x, this.y, AtlasImagenes.Moneda3D, AtlasImagenesDetalles[AtlasImagenes.Moneda3D].callbackObtenerFrame("1"))
			.setDisplaySize(160, 160)
			.setDepth(manejarDepthMainGame.profundidad3);
		this.estrellasAnimSprite = this.escena.add.sprite(this.x, this.y, AtlasImagenes.Estrellas, AtlasImagenesDetalles[AtlasImagenes.Estrellas].callbackObtenerFrame(1))
			.setDepth(manejarDepthMainGame.profundidad3)
			.setDisplaySize(150, 150)
			.setVisible(false);
	}

	verNumeroPerdedor(numero: number) {
		this.monedaPulsada = true;
		this.monedaSprite.destroy();
		this.numeroSprite = this.escena.add.sprite(this.x, this.y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.NumeroNoSorteado)
			.setAlpha(0)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setDisplaySize(135, 135);
		this.numeroTexto = this.escena.add.text(this.x, this.y, `${numero}`)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setAlpha(0)
			.setFontFamily("Gobold-Bold")
			// .setFontStyle("bold")
			.setFontSize(48)
			.setFill("#ffffff");
		Phaser.Display.Align.In.Center(this.numeroTexto, this.numeroSprite);
		this.escena.tweens.add({
			targets: [this.numeroSprite, this.numeroTexto],
			duration: 1000,
			alpha: 1,
			onStart: () => {
				//
			},
			onComplete: () => {
				//
			}
		});
	}
}
