import { AtlasBotonesImagenes, AtlasImagenes, AtlasImagenesDetalles, Imagenes, TexturasManuales, Variables } from "../Utilidades/Diccionario";
import { reproducirSonidoCambioEscena } from "../Utilidades/exportFunction";
import { ParametrosEntrada } from "../Utilidades/Intefaces";

export default class ErrorParametrosScene extends Phaser.Scene {
	public static Name = "ErrorParametrosScene";

	private fondoImagen: Phaser.GameObjects.Image;
	private botonSalir: Phaser.GameObjects.Image;
	private botonSalirTexto: Phaser.GameObjects.Text;

	public init() {
		// por implementar
	}

	public preload(): void {
		// por implementar
	}

	public create(): void {
		this.add.image(0, 0, TexturasManuales.FondoTransparente)
			.setOrigin(0, 0);

		this.fondoImagen = this.add.image(0, 0, Imagenes.BackgroundModal)
			.setDisplaySize(600, 300)
			.setOrigin(0, 0);

		this.fondoImagen.setPosition(this.cameras.main.centerX - (this.fondoImagen.displayWidth / 2), this.cameras.main.centerY - (this.fondoImagen.displayHeight / 2));
		// this.cerrar = this.add.image(this.fondoImagen.x + this.fondoImagen.displayWidth, this.fondoImagen.y, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonCerrar))
		// 	.setInteractive({ useHandCursor: true })
		// 	.on(Phaser.Input.Events.POINTER_MOVE, function () {
		// 		this.setTint(0x00ff00);
		// 	})
		// 	.on(Phaser.Input.Events.POINTER_OUT, function () {
		// 		this.clearTint();
		// 	})
		// 	.on(Phaser.Input.Events.POINTER_UP, () => {
		// 		reproducirSonidoBotones(this);
		// 		this.registry.set(Variables.EscenaActual, this.registry.get(Variables.EscenaLaunchMenu));
		// 		this.scene.resume(this.registry.get(Variables.EscenaLaunchMenu));
		// 		this.scene.stop(ConfiguracionesScene.Name);
		// 	});
		this.crearTextos();
		this.crearBotonSalir();
	}

	crearTextos() {
		const inicioY = this.fondoImagen.y + 10;
		const parametrosEntrada = <ParametrosEntrada> this.registry.get(Variables.ParametrosEntrada);
		const titulo = this.add.text(this.fondoImagen.x + 20, inicioY, "Error en los parámetros de entrada")
			.setFontFamily("Gobold-Bold")
			.setFontSize(30)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');

		this.add.text(this.fondoImagen.x + 20, inicioY + 50, "channel: ")
			.setFontFamily("Gobold-Bold")
			.setFontSize(24)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');

		this.add.text(this.fondoImagen.x + 180, inicioY + 50, parametrosEntrada?.channel || "No definido")
			.setFontFamily("Gobold-Bold")
			.setFontSize(24)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');

		this.add.text(this.fondoImagen.x + 20, inicioY + 80, "sessionToken: ")
			.setFontFamily("Gobold-Bold")
			.setFontSize(24)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');
		this.add.text(this.fondoImagen.x + 180, inicioY + 80, parametrosEntrada?.sessionToken || "No definido")
			.setFontFamily("Gobold-Bold")
			.setFontSize(24)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');

		this.add.text(this.fondoImagen.x + 20, inicioY + 110, "mode: ")
			.setFontFamily("Gobold-Bold")
			.setFontSize(24)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');
		this.add.text(this.fondoImagen.x + 180, inicioY + 110, parametrosEntrada?.mode || "No definido")
			.setFontFamily("Gobold-Bold")
			.setFontSize(24)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');
	}

	crearBotonSalir() {
		this.botonSalir = this.add.image(this.fondoImagen.x + (this.fondoImagen.displayWidth / 2), this.fondoImagen.y + 250, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonGrande))
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				reproducirSonidoCambioEscena(this);
				// window.parent.postMessage("goToLobby", "*");
				const param = <ParametrosEntrada> this.registry.get(Variables.ParametrosEntrada);
				if (param.lobbyUrl) {
					window.location.href = param.lobbyUrl;
				}
			})
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});

		this.botonSalirTexto = this.add.text(0, 0, "Salir", { color: "#ffffff"  })
			.setOrigin(0.5, 0)
			.setFontFamily("Gobold-Bold")
			.setFontSize(36)
			.setFill("#185532");
		Phaser.Display.Align.In.Center(this.botonSalirTexto, this.botonSalir);
	}
}
