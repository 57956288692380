import SplashScreen from "./SplashScreen";
import { Imagenes, Variables, AtlasImagenes, AtlasImagenesDetalles, Sonidos, SonidosDetalles, Eventos, TexturasManuales, AtlasAnimaciones, AtlasAnimacionesDetalles } from "../Utilidades/Diccionario";
import { configuracionLineasGanadoras, esDispositivoMovil, numerosAzar } from "../Utilidades/exportFunction";
import AyudaEscene from "./AyudaScene";
import { ObtenerTextoMultiIdioma, TextosAplicacion } from "../Utilidades/MultiIdioma";
import ConfiguracionesScene from "./ConfiguracionesScene";
import FooterScene from "./FooterScene";
import SeleccionRapidaEscene from "./PrincipalScene";
import { ParametrosEntrada } from "../Utilidades/Intefaces";
import { obtenerBalance } from "../Utilidades/Conexion";

export default class Preloader extends Phaser.Scene {
	public static Name = "Preloader";

	public preload(): void {
		this.addProgressBar();

		this.load.path = "assets/";
		for(const value in Imagenes) {
			this.load.image(Imagenes[value], `${Imagenes[value]}.png`);
		}

		// for(const value in SpriteImagenes) {
		// 	const spriteDetalle = SpriteDetalles[SpriteImagenes[value]];
		// 	this.load.spritesheet(SpriteImagenes[value], spriteDetalle.NombreArchivo, {
		// 		frameWidth: spriteDetalle.AnchoFrame,
		// 		frameHeight: spriteDetalle.AltoFrame,
		// 		endFrame: spriteDetalle.FrameFinal
		// 	});
		// }
		for(const value in AtlasImagenes) {
			const spriteDetalle = AtlasImagenesDetalles[AtlasImagenes[value]];
			this.load.atlas(AtlasImagenes[value], spriteDetalle.NombreArchivoImagen, spriteDetalle.NombreArchivoAtlas);
		}

		for(const value in Sonidos) {
			this.load.audio(Sonidos[value], SonidosDetalles[Sonidos[value]].NombreArchivo);
		}

		for(const value in AtlasAnimaciones) {
			const animacionDetalle = AtlasAnimacionesDetalles[AtlasAnimaciones[value]];
			this.load.animation(animacionDetalle.NombreAnimacion, animacionDetalle.NombreArchivoAtlas);
		}
	}

	public create(): void {
		this.registry.set(Variables.MusicaEfectosActivada, true);
		// this.registry.set(Variables.Saldo, 100000);
		this.registry.set(Variables.Saldo, 0);
		this.registry.set(Variables.MusicaEfectosVolumen, 5);
		this.registry.set(Variables.MusicaVolumen, 5);
		this.registry.set(Variables.IdiomaSeleccionado, 0);
		this.registry.set(Variables.MostrarAyudaInicial, true);
		this.registry.set(Variables.FechaHoraInicio, new Date());
		const param: ParametrosEntrada = JSON.parse(localStorage.getItem("param"));
		this.registry.set(Variables.ParametrosEntrada, param);
		localStorage.removeItem("param");
		// this.sound.play(Sonidos.MusicaFondo, { loop: true });
		this.crearTexturas();
		this.crearTexturasConTextoMultiIdioma();
		this.registry.events.on(Eventos.MenuAbrirAyuda, (nombreEscena) => {
			this.registry.set(Variables.EscenaActual, AyudaEscene.Name);
			this.scene.launch(AyudaEscene.Name);
		});

		this.registry.events.on(Eventos.MenuAbrirConfiguraciones, (nombreEscena) => {
			this.registry.set(Variables.EscenaActual, ConfiguracionesScene.Name);
			this.scene.launch(ConfiguracionesScene.Name);
		});

		// obtenerBalance(this.registry.get(Variables.ParametrosEntrada), () => this.registry.set(Variables.BackendErrorBalance, true))
		// 	.then((datosUsuario) => {
		// 		this.registry.set(Variables.Saldo, datosUsuario?.balance || 0);
		// 		this.registry.events.emit(Eventos.CambiosSaldoCliente);
		// 	});

		this.registry.events.on(Eventos.CambiarIdioma, (nombreEscena) => {
			(<ConfiguracionesScene> this.scene.get(ConfiguracionesScene.Name))?.cambioTextosIdioma();
			(<FooterScene> this.scene.get(FooterScene.Name))?.cambioTextosIdioma();

			// if (nombreEscena === MainGame.Name) {
			// 	const auxMainGameScene = <MainGame> this.scene.get(MainGame.Name);
			// 	if (auxMainGameScene?.scene.settings.visible) {
			// 		auxMainGameScene.cambioTextosIdioma();
			// 	}
			// }

			if (nombreEscena === SeleccionRapidaEscene.Name) {
				const auxSeleccionRapidaScene = <SeleccionRapidaEscene> this.scene.get(SeleccionRapidaEscene.Name);
				if (auxSeleccionRapidaScene?.scene.settings.visible) {
					auxSeleccionRapidaScene.cambioTextosIdioma();
				}
			}

			this.crearTexturasConTextoMultiIdioma();
		});

		//Phaser.Scale.Events.RESIZE
		const callbackEventoResize = () => {
			const ancho = window.innerWidth;
			const alto = window.innerHeight;
			if (alto < ancho) {
				document.getElementById('rotar-dispositivo').style.display = 'none';
				this.scene.resume(this.registry.get(Variables.EscenaActual));
				// this.game.scene.resume;
			} else {
				if (this.scale.isFullscreen) {
					this.scale.stopFullscreen();
				}
				document.getElementById('rotar-dispositivo').style.display = 'block';
				this.scene.pause(this.registry.get(Variables.EscenaActual));
			}
		};
		this.scale.on(Phaser.Scale.Events.RESIZE, (gameSize, baseSize, displaySize, resolution) => {
			// const width = gameSize.width;
			// const height = gameSize.height;
			// const width1 = baseSize.width;
			// const height1 = baseSize.height;
			// const width2 = displaySize.width;
			// const height2 = displaySize.height;
			// const width3 = resolution.width;
			// const height3 = resolution.height;
			// console.log({width, height, width1, height1, width2, height2, width3, height3});
			// console.log("evento resize");
			callbackEventoResize();

			const parametrosEntrada = <ParametrosEntrada> this.registry.get(Variables.ParametrosEntrada);
			if (parametrosEntrada?.channel === '3' && !esDispositivoMovil()) {
				(<FooterScene> this.scene.get(FooterScene.Name))?.mostrarTiempo(this.scale.isFullscreen);
			}
			// console.log({gameSize, baseSize, displaySize, resolution});

			// if (window.innerHeight < window.innerWidth) {
			// 	document.getElementById('orientation').style.display = 'none';
			// 	if (this.game.paused) {
			// 	  this.game.paused = false;
			// 	}
			//   } else {
			// 	if (this.game.scale.isFullScreen) {
			// 	  this.game.scale.stopFullScreen(true);
			// 	}

			// 	var orientationPanel = document.getElementById('orientation');
			// 	orientationPanel.style.display = 'block';
			// 	this.game.paused = true;
			// 	void 0;
			//   }
		});

		this.scene.start(SplashScreen.Name);
		callbackEventoResize();
	}

	public update(): void {
		// preload handles updates to the progress bar, so nothing should be needed here.
	}

	/**
	 * Adds a progress bar to the display, showing the percentage of assets loaded and their name.
	 */
	private addProgressBar(): void {
		const width = this.cameras.main.width;
		const height = this.cameras.main.height;
		/** Customizable. This text color will be used around the progress bar. */
		const outerTextColor = '#ffffff';

		const progressBar = this.add.graphics();
		const progressBox = this.add.graphics();
		progressBox.fillStyle(0xb3b3b3, 0.8);
		progressBox.fillRect(width / 4, height / 2 - 30, width / 2, 50);

		const loadingText = this.make.text({
			x: width / 2,
			y: height / 2 - 50,
			text: "Cargando...",
			style: {
				font: "20px monospace",
				color: outerTextColor
			}
		});
		loadingText.setOrigin(0.5, 0.5);

		const percentText = this.make.text({
			x: width / 2,
			y: height / 2 - 5,
			text: "0%",
			style: {
				font: "18px monospace",
				color: "#ffffff"
			}
		});
		percentText.setOrigin(0.5, 0.5);

		const assetText = this.make.text({
			x: width / 2,
			y: height / 2 + 50,
			text: "",
			style: {
				font: "18px monospace",
				color: outerTextColor
			}
		});

		assetText.setOrigin(0.5, 0.5);

		this.load.on("progress", (value: number) => {
			percentText.setText(parseInt(value * 100 + "", 10) + "%");
			progressBar.clear();
			progressBar.fillStyle(0xffffff, 1);
			progressBar.fillRect((width / 4) + 10, (height / 2) - 30 + 10, (width / 2 - 10 - 10) * value, 30);
		});

		this.load.on("fileprogress", (file: Phaser.Loader.File) => {
			assetText.setText("Cargando recurso: " + file.key);
		});

		this.load.on("complete", () => {
			progressBar.destroy();
			progressBox.destroy();
			loadingText.destroy();
			percentText.destroy();
			assetText.destroy();
		});
	}

	private crearTexturas() {
		const texturaFondo = this.make.renderTexture({ width: this.cameras.main.displayWidth, height: this.cameras.main.displayHeight }, false);
		texturaFondo.fill(0xffffff, 0.2, 0, 0, this.cameras.main.displayWidth, this.cameras.main.displayHeight);
		texturaFondo.saveTexture(TexturasManuales.FondoTransparente);
	}

	private crearTexturasConTextoMultiIdioma() {
		const anchoTextura = 1050;
		const titulosAlingX = 475;
		const detalleAlingX = 50;
		// const linea = this.add.graphics();
		// linea.lineStyle(8, 0x80effc, 1);
		// linea.visible = false;
		// linea.strokeRectShape(new Phaser.Geom.Rectangle(0, 0, anchoTextura, 400));

		let texturaAyuda1 = this.make.renderTexture({ width: anchoTextura, height: 700 }, false);
		// texturaAyuda1.draw(linea, 0, 0);
		let imagen = this.add.image(0, 0, Imagenes.Ayuda1).setVisible(false).setOrigin(0, 0);
		texturaAyuda1.draw(imagen, 300, 525);

		let text = this.add.text(0, 0, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.AyudaScene_Ayuda1Titulo), { fontFamily: 'Gobold-Bold', fontSize: 48, fontStyle: 'bold', color: '#000000'})
			.setOrigin(0.5, 0);
		texturaAyuda1.draw(text, titulosAlingX, 30);
		let textCantidad = this.add.text(0, 0, '1 de 3', { fontFamily: 'Gobold-Bold', fontSize: 24, fontStyle: 'bold', color: '#000000'})
			.setOrigin(0.5, 0);
		texturaAyuda1.draw(textCantidad, titulosAlingX, 90);
		let textoAux = this.add.text(0, 0, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.AyudaScene_Ayuda1Detalle), { fontFamily: 'Gobold-Bold', fontSize: 40, fontStyle: 'bold', color: '#000000'});
		texturaAyuda1.draw(textoAux, detalleAlingX, 130);
		texturaAyuda1.saveTexture(TexturasManuales.AyudaComoJugar);

		texturaAyuda1 = this.make.renderTexture({ width: anchoTextura, height: 700 }, false);
		// texturaAyuda1.draw(linea, 0, 0);
		imagen = this.add.image(0, 0, Imagenes.Ayuda2).setVisible(false).setOrigin(0, 0).setDisplaySize(420, 107);
		texturaAyuda1.draw(imagen, 300, 400);
		text = this.add.text(0, 0, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.AyudaScene_Ayuda2Titulo), { fontFamily: 'Gobold-Bold', fontSize: 48, fontStyle: 'bold', color: '#000000'})
			.setOrigin(0.5, 0);
		texturaAyuda1.draw(text, titulosAlingX, 30);
		textCantidad = this.add.text(0, 0, '2 de 3', { fontFamily: 'Gobold-Bold', fontSize: 24, fontStyle: 'bold', color: '#000000'})
			.setOrigin(0.5, 0);
		texturaAyuda1.draw(textCantidad, titulosAlingX, 90);

		textoAux = this.add.text(0, 0, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.AyudaScene_Ayuda2Detalle), { fontFamily: 'Gobold-Bold', fontSize: 40, fontStyle: 'bold', color: '#000000'});
		texturaAyuda1.draw(textoAux, detalleAlingX, 130);
		texturaAyuda1.saveTexture(TexturasManuales.AyudaJuegos);
		// const listaLineasGanadoras = configuracionLineasGanadoras();
		// listaLineasGanadoras.forEach(x => {
		// 	//
		// });

		texturaAyuda1 = this.make.renderTexture({ width: anchoTextura, height: 700 }, false);
		// texturaAyuda1.draw(linea, 0, 0);
		imagen = this.add.image(0, 0, Imagenes.Ayuda3).setVisible(false).setOrigin(0, 0).setDisplaySize(210, 250);
		texturaAyuda1.draw(imagen, 400, 375);
		text = this.add.text(0, 0, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.AyudaScene_Ayuda3Titulo), { fontFamily: 'Gobold-Bold', fontSize: 48, fontStyle: 'bold', color: '#000000'})
			.setOrigin(0.5, 0);
		texturaAyuda1.draw(text, titulosAlingX, 30);
		textCantidad = this.add.text(0, 0, '3 de 3', { fontFamily: 'Gobold-Bold', fontSize: 24, fontStyle: 'bold', color: '#000000'})
			.setOrigin(0.5, 0);
		texturaAyuda1.draw(textCantidad, titulosAlingX, 90);
		textoAux = this.add.text(0, 0, ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.AyudaScene_Ayuda3Detalle), { fontFamily: 'Gobold-Bold', fontSize: 40, fontStyle: 'bold', color: '#000000'});
		texturaAyuda1.draw(textoAux, detalleAlingX, 130);
		texturaAyuda1.saveTexture(TexturasManuales.AyudaGanarBonus);

	}
}
