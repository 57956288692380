import { AtlasBotonesImagenes, AtlasImagenes, AtlasImagenesDetalles, Imagenes, TexturasManuales, Variables } from "../Utilidades/Diccionario";
import { reproducirSonidoBotones } from "../Utilidades/exportFunction";

export default class AyudaEscene extends Phaser.Scene {
	public static Name = "AyudaEscene";
	private fondoImagen: Phaser.GameObjects.Image;
	private cerrar: Phaser.GameObjects.Image;
	private fotoActualAyuda: Phaser.GameObjects.Image;
	private carruselCirculos: Phaser.GameObjects.Image[];
	// private carruselCirculosSeleccionado: Phaser.GameObjects.Graphics[];
	private imagenesAyuda: string[] = [TexturasManuales.AyudaComoJugar, TexturasManuales.AyudaJuegos, TexturasManuales.AyudaGanarBonus];
	private imagenIndexMostrando: number;
	public init() {
		this.carruselCirculos = [];
		// this.carruselCirculosSeleccionado = [];
		this.imagenIndexMostrando = 0;
	}

	public preload(): void {
		// por implementar
	}

	public create(): void {
		this.add.image(0, 0, TexturasManuales.FondoTransparente)
			.setOrigin(0, 0)
			.setInteractive()
			.on(Phaser.Input.Events.POINTER_UP, () => {
				// if (!this.cerrandoEscena) {
				// 	this.cerrarEscena();
				// }
			});

		this.fondoImagen = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, Imagenes.BackgroundModal).setDisplaySize(1100, 708);
		this.cerrar = this.add.image(this.fondoImagen.x + (this.fondoImagen.displayWidth / 2), this.fondoImagen.y - (this.fondoImagen.displayHeight / 2), AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonCerrar))
			// .setScale(0.5)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			})
			.on(Phaser.Input.Events.POINTER_UP, () => {
				reproducirSonidoBotones(this);
				this.registry.set(Variables.EscenaActual, this.registry.get(Variables.EscenaLaunchMenu));
				this.scene.resume(this.registry.get(Variables.EscenaLaunchMenu));
				this.scene.stop(AyudaEscene.Name);
			});

		this.fotoActualAyuda = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, TexturasManuales.AyudaComoJugar)
			// .setOrigin(0, 0)
			.setInteractive()
			.on(Phaser.Input.Events.POINTER_UP, () => {
				this.cambiarImagen(this.imagenIndexMostrando + 1);
			});
		// this.crearCarrusel();
		// this.carruselCirculosSeleccionado[0].visible = true;
		this.crearBotonSiguiente();
		this.crearBotonAnterior();
	}

	crearBotonSiguiente() {
		this.add.image(this.cameras.main.centerX + (this.fondoImagen.displayWidth / 2), this.cameras.main.centerY, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonSiguiente))
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				this.cambiarImagen(this.imagenIndexMostrando + 1);
			});
	}

	crearBotonAnterior() {
		this.add.image(this.cameras.main.centerX - (this.fondoImagen.displayWidth / 2), this.cameras.main.centerY, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonAnterior))
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				this.cambiarImagen(this.imagenIndexMostrando - 1);
			});
	}
	// crearCarrusel() {
	// 	this.carruselCirculos = [];
	// 	let posX = 500;
	// 	this.imagenesAyuda.forEach((item, index) => {
	// 		const circuloCarrusel = this.add.sprite(posX, 500, SpriteImagenes.Circulos)
	// 			.setInteractive({ useHandCursor: true })
	// 			.on(Phaser.Input.Events.POINTER_UP, () => {
	// 				this.cambiarImagen(index);
	// 			});
	// 		const graphics = this.add.graphics({ fillStyle: { color: 0x92d9f7 } });
	// 		graphics.visible = false;
	// 		const circulo1 = new Phaser.Geom.Circle(posX, 500, 7);
	// 		graphics.fillCircleShape(circulo1);
	// 		this.carruselCirculosSeleccionado.push(graphics);
	// 		posX += 30;
	// 	});
	// }

	cambiarImagen(nuevoIndex: number) {
		if (this.imagenIndexMostrando === nuevoIndex) {
			return;
		}

		reproducirSonidoBotones(this);
		if (nuevoIndex > this.imagenesAyuda.length - 1) {
			nuevoIndex = 0;
		}

		if (nuevoIndex < 0) {
			nuevoIndex = this.imagenesAyuda.length - 1;
		}

		// const moverIzquierda = this.imagenIndexMostrando < nuevoIndex;
		this.imagenIndexMostrando = nuevoIndex;
		// for (const iterator of this.carruselCirculosSeleccionado) {
		// 	iterator.visible = false;
		// }
		// this.carruselCirculosSeleccionado[nuevoIndex].visible = true;

		this.tweens.add({
			targets: this.fotoActualAyuda,
			duration: 500,
			alpha: 0.1,
			// x: moverIzquierda ? -1000 : 1500,
			onStart: () => {
				// this.bola.y = !incrementar ? 200 : 400;
			},
			onComplete: () => {
				this.fotoActualAyuda.setTexture(this.imagenesAyuda[nuevoIndex]);
				this.tweens.add({
					targets: this.fotoActualAyuda,
					duration: 500,
					alpha: 1,
					// x: this.cameras.main.centerX,
					onStart: () => {
						// this.fotoActualAyuda.x = moverIzquierda ? 1500 : -1000;
						this.fotoActualAyuda.alpha = 0.1;
					},
					onComplete: () => {
						//
					}
				});
			}
		});
	}
}