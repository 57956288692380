import { Variables } from "./Diccionario";
import { configuracionLineasGanadoras, numerosSorteadosAzar } from "./exportFunction"
import { DatosUsuario, JuegoSorteado, ParametrosEntrada, ResultadosSorteo } from "./Intefaces";
import { v4 as uuidv4 } from 'uuid';
import { configuracion } from "../Config/config";

export function obtenerSorteo(apuesta: number, parametros: ParametrosEntrada, callbackMarcarErrorRequest: () => void): Promise<ResultadosSorteo> {
	// const r1 =  new Promise<null>(resolve => {
	// 	setTimeout(() => resolve(null), 1500);
	// });

	// const request = new Promise<ResultadosSorteo>(resolve => {
	// 	fetch(`${configuracion.URL_BASE}/play`
	// 		, {
	// 			method: 'POST',
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 				'Guid': uuidv4(),
	// 				'Channel': parametros.channel,
	// 				'sessionToken': parametros.sessionToken,
	// 				'mode': parametros.mode,
	// 				'loto-express': '1',
	// 			},
	// 			body: JSON.stringify({
	// 				seleccion: bolasSeleccionadas,
	// 				monto: montoPorLinea,
	// 				lineas: cantidadLineasJugadas
	// 			})
	// 		})
	// 		.then(response => {
	// 			if(response.ok)
	// 			{
	// 				window.parent.postMessage("checkout", "*");
	// 				return response.json();
	// 			}
	// 			callbackMarcarErrorRequest();
	// 		})
	// 		.then((data: ResultadosSorteo) => {
	// 			data?.lineasGanadoras?.forEach(item => {
	// 				item.lineaGanadora -= 1;
	// 			});
	// 			return resolve(data);
	// 		});
	// });
	// const promiseFinal = Promise.all([r1, request]).then((data) => data[1]);
	// return promiseFinal;

	return new Promise((resolve, reject) => {
		setTimeout(() => {
			const listaJuegoSorteado: JuegoSorteado[] = [];
			const listaNumeros = numerosSorteadosAzar();
			for (let index = 0; index < 4; index++) {
				const juego: JuegoSorteado = {
					numerosSorteados: listaNumeros.slice(index * 3, index * 3 + 3).map(item => ({ numero: item, visualizado: false })),
					sumaNumeros: listaNumeros.slice(index * 3, index * 3 + 3).reduce((a, b) => a + b, 0),
					montoGanado: listaNumeros.slice(index * 3, index * 3 + 3).reduce((a, b) => a + b, 0) % 7 === 0 && listaNumeros.slice(index * 3, index * 3 + 3).reduce((a, b) => a + b, 0) <= 28 ? 400 * Phaser.Math.Between(2, 5000) : 0
				}
				listaJuegoSorteado.push(juego);
			}
			const listaNumerosSorteados = listaJuegoSorteado.flatMap(x => x.numerosSorteados.flatMap(y => y.numero));
			const sorteo: ResultadosSorteo = {
				idSorteo: uuidv4(),
				balance: 10000,
				juegos: listaJuegoSorteado,
				totalGanado: listaJuegoSorteado.reduce((a, b) => a + b.montoGanado, 0) || 0,
				numerosSinSortear: Phaser.Utils.Array.NumberArray(1, 15).map(x => <number> x).filter(x => !listaNumerosSorteados.includes(x)),
			};
			return resolve(sorteo);
		}, Phaser.Math.Between(500, 1000));
	});
}

export function obtenerBalance(parametros: ParametrosEntrada, callbackMarcarErrorRequest: () => void ): Promise<DatosUsuario> {
	return new Promise<DatosUsuario>(resolve => {
		fetch(`${configuracion.URL_BASE}/setting`
			, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Guid': uuidv4(),
					'Channel': parametros.channel,
					'sessionToken': parametros.sessionToken,
					'mode': parametros.mode,
					'loto-express': '1',
				},
			})
			.then(response => {
				if(response.ok)
				{
					// return response.text();
					return response.json();
				}
				callbackMarcarErrorRequest();
				// alert("Error al obtener el balance.");
				// throw new Error('Error al obtener el balance.');

			})
			.then((data: DatosUsuario) => {
				return resolve(data);
			});
		// .catch(error => {
		// 	console.log("----------------------------------------");
		// 	console.log(error);
		// 	callbackMarcarErrorRequest();
		// });
	});
	// return new Promise((resolve, reject) => {
	// 	resolve({
	// 		balance: 10000
	// 	});
	// });
}
