import { AtlasImagenes, AtlasImagenesVarias, Imagenes, manejarDepthMainGame } from "../Utilidades/Diccionario";

export class EfectoGanadorComponent {
	private imagenBackground: Phaser.GameObjects.Image;
	private ganasteTexto: Phaser.GameObjects.Text;
	private ganasteMontoTexto: Phaser.GameObjects.Text;
	private particulasEmitterFuego: Phaser.GameObjects.Particles.ParticleEmitter;
	private rectangulo: Phaser.Geom.Rectangle;

	constructor(private escena: Phaser.Scene) {
		this.rectangulo = new Phaser.Geom.Rectangle(this.escena.cameras.main.centerX - 500, this.escena.cameras.main.centerY + 50, 600, 250);
	}

	mostrar(montoGanado: number) {
		this.imagenBackground = this.escena.add.image(this.escena.cameras.main.centerX -200, this.escena.cameras.main.centerY + 170, Imagenes.Background)
			.setAlpha(0.8)
			.setDisplaySize(600, 250)
			.setDepth(manejarDepthMainGame.profundidad7);

		this.ganasteTexto = this.escena.add.text(this.escena.cameras.main.centerX - 200, this.escena.cameras.main.centerY + 120, `Ganaste`)
			.setOrigin(0.5, 0.5)
			.setDepth(manejarDepthMainGame.profundidad7)
			.setFontFamily("Gobold-Bold")
			.setFontSize(64)
			.setFill("#c167d2")
			.setStroke("#ffffff", 6);
		this.ganasteMontoTexto = this.escena.add.text(this.escena.cameras.main.centerX - 200, this.escena.cameras.main.centerY + 220, `$ ${montoGanado?.toLocaleString("es-CL")}`)
			.setOrigin(0.5, 0.5)
			.setDepth(manejarDepthMainGame.profundidad7)
			.setFontFamily("Gobold-Bold")
			.setFontSize(64)
			.setFill("#c167d2")
			.setStroke("#ffffff", 6);
		// const emitZone1 = { type: 'edge', source: imagen.getBounds(), quantity: 42 };
		this.particulasEmitterFuego = this.escena.add.particles(0, 0, AtlasImagenes.ImagenesVarias,
			{
				frame: AtlasImagenesVarias.FuegoAmarillo,
				// frame: { frames: [ AtlasImagenesVarias.Gema1, AtlasImagenesVarias.Gema2, AtlasImagenesVarias.Gema3, AtlasImagenesVarias.Gema4 ], cycle: true },
				// x: 400,
				// y: 300,
				scale: { start: 0.5, end: 0 },
				blendMode: 'ADD',
				emitZone: { type: 'edge', source: this.rectangulo, quantity: 48, yoyo: false }
			})
			.setDepth(manejarDepthMainGame.profundidad7 + 1);
		// this.mostrarParticulasBorde();
	}

	ocultar() {
		this.imagenBackground?.destroy();
		this.ganasteTexto?.destroy();
		this.ganasteMontoTexto?.destroy();
		this.particulasEmitterFuego?.stop();
		this.particulasEmitterFuego?.destroy();
	}

	// private mostrarParticulasBorde() {
	// 	this.particulasEmitterGemas = [];
	// 	const listaPoints = this.rectanguloMayor.getPoints(36);
	// 	for (let i = 0; i < listaPoints.length; i++) {
	// 		const p = listaPoints[i];
	// 		const emitter = this.particulasGemas.createEmitter({
	// 			// frame: { frames: [ AtlasImagenesVarias.Gema3, AtlasImagenesVarias.Gema4 ], cycle: true },
	// 			frame: AtlasImagenesVarias.Gema4,
	// 			x: p.x,
	// 			y: p.y,
	// 			// angle: angle,
	// 			speed: { min: -100, max: 500 },
	// 			gravityY: 200,
	// 			scale: { start: 0.1, end: 0.01 },
	// 			lifespan: 200,
	// 			blendMode: 'SCREEN'
	// 		});
	// 		this.particulasEmitterGemas.push(emitter);
	// 	}

	// 	// const p0 = new Phaser.Math.Vector2(200, 500);
	// 	// const p1 = new Phaser.Math.Vector2(200, 200);
	// 	// const p2 = new Phaser.Math.Vector2(600, 200);
	// 	// const p3 = new Phaser.Math.Vector2(600, 500);
	// 	// const curve = new Phaser.Curves.CubicBezier(p0, p1, p2, p3);
	// 	// const max = 28;
	// 	// const points = [];
	// 	// const tangents = [];
	// 	// for (let c = 0; c <= max; c++)
	// 	// {
	// 	// 	const t = curve.getUtoTmapping(c / max, undefined);
	// 	// 	points.push(curve.getPoint(t));
	// 	// 	tangents.push(curve.getTangent(t));
	// 	// }
	// 	// const tempVec = new Phaser.Math.Vector2();
	// 	// for (let i = 0; i < points.length; i++) {
	// 	// 	const p = points[i];
	// 	// 	tempVec.copy(tangents[i]).normalizeRightHand().scale(-32).add(p);
	// 	// 	const angle = Phaser.Math.RadToDeg(Phaser.Math.Angle.BetweenPoints(p, tempVec));
	// 	// 	this.particulasGemas.createEmitter({
	// 	// 		frame: { frames: [ AtlasImagenesVarias.Gema1, AtlasImagenesVarias.Gema2, AtlasImagenesVarias.Gema3, AtlasImagenesVarias.Gema4 ], cycle: true },
	// 	// 		x: tempVec.x,
	// 	// 		y: tempVec.y,
	// 	// 		angle: angle,
	// 	// 		speed: { min: -100, max: 500 },
	// 	// 		gravityY: 200,
	// 	// 		scale: { start: 0.1, end: 0.01 },
	// 	// 		lifespan: 800,
	// 	// 		blendMode: 'SCREEN'
	// 	// 	});
	// 	// }
	// }
}