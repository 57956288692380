import { AtlasImagenes, AtlasImagenesVarias, Imagenes } from "../Utilidades/Diccionario";
import PrincipalScene from "./PrincipalScene";

export default class SplashScreen extends Phaser.Scene {
	public static Name = "SplashScreen";

	public preload(): void {
		//
	}

	public create(): void {
		// this.lights.enable().setAmbientColor(0x555555);
		this.add.image(0, 0, Imagenes.Background).setOrigin(0, 0).setDisplaySize(this.cameras.main.displayWidth, this.cameras.main.displayHeight);
		// this.habilitarLucesVerticales();
		// this.habilitarLucesHorizontales();
		this.verEstrellas();
		// this.efectoLogoNormal();
		this.efectoLogo();
	}

	private loadMainMenu(): void {
		this.cameras.main.fadeOut(500, 0, 0, 0);
		this.scene.start(PrincipalScene.Name);
	}

	private verEstrellas() {
		const listaEstrellas = this.add.group(null, { key: AtlasImagenes.ImagenesVarias, frame: AtlasImagenesVarias.Estrella, repeat: 15 });
		(<Phaser.GameObjects.Sprite[]> listaEstrellas.getChildren()).forEach(item => {
			item.setDisplaySize(27, 27);
			// item.setPipeline('Light2D');
		});

		// this.cameras.main.centerX, this.cameras.main.centerY
		const circle = new Phaser.Geom.Circle(this.cameras.main.centerX, this.cameras.main.centerY + 30, 100);
		Phaser.Actions.PlaceOnCircle(listaEstrellas.getChildren(), circle);
		this.tweens.add({
			targets: circle,
			radius: 150,
			ease: 'Quintic.easeInOut',
			duration: 1500,
			yoyo: true,
			//repeat: -1,
			onUpdate: () =>
			{
				Phaser.Actions.RotateAroundDistance(listaEstrellas.getChildren(), { x: this.cameras.main.centerX, y: this.cameras.main.centerY + 30 }, 0.02, circle.radius);
			},
			onComplete: () => {
				this.tweens.add({
					targets: circle,
					radius: 1500,
					ease: 'Quintic.easeInOut',
					duration: 1500,
					onUpdate: () =>
					{
						Phaser.Actions.RotateAroundDistance(listaEstrellas.getChildren(), { x: this.cameras.main.centerX, y: this.cameras.main.centerY + 30 }, 0.02, circle.radius);
					},
				});
			}
		});
	}

	private habilitarLucesVerticales() {
		const hsv = Phaser.Display.Color.HSVColorWheel();

		const radius = 50;
		const intensity = 6;
		let x = radius + 150;
		let y = 0;
		const maxLights = 6;
		// const listaLuces: Phaser.GameObjects.GameObject[] = [];
		// for (let i = 0; i < maxLights; i++) {
		// 	const color = Phaser.Display.Color.GetColor32(hsv[i * 10].r, hsv[i * 10].g, hsv[i * 10].b, hsv[i * 10].a);
		// 	const light = this.lights.addLight(x, y, radius, color, intensity);
		// 	listaLuces.push(light);

		// }

		for (let i = 0; i < maxLights; i++)
		{
			const color = Phaser.Display.Color.GetColor32(hsv[i * 10].r, hsv[i * 10].g, hsv[i * 10].b, hsv[i * 10].a);
			const light = this.lights.addLight(x, y, radius, color, intensity);
			this.tweens.add({
				targets: light,
				y: 600,
				yoyo: true,
				// repeat: -1,
				ease: 'Sine.easeInOut',
				duration: 1500,
				delay: i * 100,
				onComplete: () => light.setVisible(false),
			});

			x += radius * 2;

			if (x > 900)
			{
				x = radius;
				y += radius;
			}
		}
	}

	private habilitarLucesHorizontales() {
		const hsv = Phaser.Display.Color.HSVColorWheel();

		const radius = 50;
		const intensity = 6;
		const x = 0;
		let y = radius + 150;
		const maxLights = 6;
		// const listaLuces: Phaser.GameObjects.GameObject[] = [];
		// for (let i = 0; i < maxLights; i++) {
		// 	const color = Phaser.Display.Color.GetColor32(hsv[i * 10].r, hsv[i * 10].g, hsv[i * 10].b, hsv[i * 10].a);
		// 	const light = this.lights.addLight(x, y, radius, color, intensity);
		// 	listaLuces.push(light);

		// }

		for (let i = 0; i < maxLights; i++)
		{
			const color = Phaser.Display.Color.GetColor32(hsv[i * 10].r, hsv[i * 10].g, hsv[i * 10].b, hsv[i * 10].a);
			const light = this.lights.addLight(x, y, radius, color, intensity);
			this.tweens.add({
				targets: light,
				x: 900,
				yoyo: true,
				// repeat: -1,
				ease: 'Sine.easeInOut',
				duration: 1500,
				delay: i * 100,
				onComplete: () => light.setVisible(false),
			});

			y += radius * 2;

			// if (x > 900)
			// {
			// 	x = radius;
			// 	y += radius;
			// }
		}
	}

	private efectoLogoNormal() {
		const poweredByText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY - 25, "Powered By");

		poweredByText.setFontFamily("monospace").setFontSize(20).setFill("#fff");
		poweredByText.setVisible(false);
		const texturaLogo = this.make.renderTexture({ width: 150, height: 150 }, false);
		texturaLogo.draw(poweredByText, 10, 0);
		// texturaLogo.draw(Imagenes.LogoFabrica, 0, 25);
		texturaLogo.saveTexture('texturaLogoFabrica');
		const imagen = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY + 50, 'texturaLogoFabrica').setDisplaySize(15, 15).setAlpha(0.1).setPipeline('Light2D');
		const light = this.lights.addLight(this.cameras.main.centerX, this.cameras.main.centerY + 50, 0).setIntensity(3);
		this.tweens.chain({
			totalDuration: 4000,
			tweens: [
				{
					targets: imagen,
					displayHeight: 150,
					displayWidth: 150,
					alpha: 1,
				},
				{
					targets: imagen,
					angle: 720
				},
				{
					offset: 1000,
					targets: light,
					// duration: 3000,
					radius: 180
				},
				{
					// offset: 1000,
					targets: light,
					duration: 1000,
					radius: 150
				},
			],
			onComplete: () => {
				this.loadMainMenu();
			},
		});
	}

	private efectoLogo() {
		// const poweredByText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY - 25, "Powered By");

		// poweredByText.setFontFamily("monospace").setFontSize(20).setFill("#fff");
		// poweredByText.setVisible(false);
		// const texturaLogo = this.make.renderTexture({ width: 150, height: 150 }, false);
		// texturaLogo.draw(poweredByText, 10, 0);
		// texturaLogo.draw(Imagenes.LogoFabrica, 0, 25);
		// texturaLogo.saveTexture('texturaLogoFabrica');
		// const imagen = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY + 50, 'texturaLogoFabrica').setDisplaySize(15, 15).setAlpha(0.1).setPipeline('Light2D');
		const imagen = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY + 30, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.Polla).setAlpha(0.1);
		const light = this.lights.addLight(this.cameras.main.centerX, this.cameras.main.centerY + 50, 0).setIntensity(2);
		this.tweens.chain({
			totalDuration: 4000,
			tweens: [
				{
					targets: imagen,
					alpha: 1,
				},
				{
					offset: 1000,
					targets: light,
					// duration: 3000,
					radius: 180
				},
				{
					// offset: 1000,
					targets: light,
					duration: 1000,
					radius: 150
				},
			],
			onComplete: () => {
				this.loadMainMenu();
			},
		});
	}
}
