import { AtlasAnimaciones, AtlasAnimacionesDetalles, AtlasImagenes, AtlasImagenesDetalles, AtlasImagenesVarias, manejarDepthMainGame } from "../Utilidades/Diccionario";
import { IObjeto3D, JuegoSorteado } from "../Utilidades/Intefaces";
import { reproducirSonidoTerminarMostrarNumero, reproducirSonidoWin } from "../Utilidades/exportFunction";

export class JuegoComponent implements IObjeto3D {
	private signoInterrogacion: Phaser.GameObjects.Sprite;
	private numeroGanadorSprite: Phaser.GameObjects.Sprite;
	private numeroGanadorTexto: Phaser.GameObjects.Text;
	// private cuadroGanadorArriba: Phaser.GameObjects.Image;
	// private cuadroGanadorMedio: Phaser.GameObjects.Image;
	// private cuadroGanadorAbajo: Phaser.GameObjects.Image;
	private numeroGanadorPrimeroImagen: Phaser.GameObjects.Image;
	private numeroGanadorSegundoImagen: Phaser.GameObjects.Image;
	private numeroGanadorTerceroImagen: Phaser.GameObjects.Image;

	private numeroGanadorPrimeroTexto: Phaser.GameObjects.Text;
	private numeroGanadorSegundoTexto: Phaser.GameObjects.Text;
	private numeroGanadorTerceroTexto: Phaser.GameObjects.Text;

	private estrellasAnimPrimera: Phaser.GameObjects.Sprite;
	private estrellasAnimSegunda: Phaser.GameObjects.Sprite;
	private estrellasAnimTercera: Phaser.GameObjects.Sprite;

	private signoMasPrimeroTexto: Phaser.GameObjects.Text;
	private signoMasSegundoTexto: Phaser.GameObjects.Text;

	private resultadoJuego: JuegoSorteado;
	private ganadorCuadro: Phaser.GameObjects.Image;
	private ganadorTexto: Phaser.GameObjects.Text;

	private coordenasCuadroGanador: {x: number, y: number}[];
	private particlesEmitterExplosionMonedas: Phaser.GameObjects.Particles.ParticleEmitter;
	private particlesEmitterExplosionSignoInterrogacion: Phaser.GameObjects.Particles.ParticleEmitter;
	private particlesEmitterExplosionGemas: Phaser.GameObjects.Particles.ParticleEmitter;

	constructor(private escena: Phaser.Scene, private x: number, private y: number, private numeroJuego: number) {

		this.coordenasCuadroGanador = [ { x: this.x + 40, y: this.y + 100 }, { x: this.x + 140, y: this.y + 100 }, { x: this.x + 240, y: this.y + 100 }]
		this.crear();
		this.crearEmitterMonedas();
		this.crearEmitterSignoInterrogacion();
		this.crearEmitterGemas();
	}

	girarStartObjeto() {
		// this.cuadroGanadorArriba?.setVisible(false);
		// this.cuadroGanadorMedio?.setVisible(false);
		// this.cuadroGanadorAbajo?.setVisible(false);
		this.numeroGanadorPrimeroImagen?.destroy();
		this.numeroGanadorSegundoImagen?.destroy();
		this.numeroGanadorTerceroImagen?.destroy();
		this.numeroGanadorPrimeroTexto?.destroy();
		this.numeroGanadorSegundoTexto?.destroy();
		this.numeroGanadorTerceroTexto?.destroy();
		// this.signoMasPrimeroTexto.setVisible(false);
		// this.signoMasSegundoTexto.setVisible(false);
		this.ganadorCuadro.setVisible(false);
		this.ganadorTexto?.destroy();

		if (this.numeroGanadorSprite) {
			// this.particlesEmitterExplosionSignoInterrogacion.explode(12, this.numeroGanadorSprite.x, this.numeroGanadorSprite.y);
			this.particlesEmitterExplosionGemas.explode(18, this.signoInterrogacion.x, this.signoInterrogacion.y);
		}
		this.numeroGanadorTexto?.destroy();
		this.numeroGanadorSprite?.destroy();
		this.signoInterrogacion.setVisible(true);
		this.signoInterrogacion.anims.play({
			key: AtlasAnimacionesDetalles[AtlasAnimaciones.Question3D].NombreAnimacion,
			// frameRate: Phaser.Math.Between(6, 20)
			frameRate: 12
		}, true);
	}

	private crear() {
		const juegoMarco = this.escena.add.image(this.x, this.y + 50, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoMarco)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setOrigin(0, 0);
		const juegoNumeroBoton = this.escena.add.image(this.x + (juegoMarco.displayWidth / 2), this.y + 50, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoNumero)
			.setDepth(manejarDepthMainGame.profundidad4);
		const juegoNumeroTexto = this.escena.add.text(this.x + 60, this.y + 25, `Juego ${this.numeroJuego}`)
			// .setOrigin(0.5, 0)
			.setDepth(manejarDepthMainGame.profundidad4 + 1)
			.setFontFamily("Gobold-Bold")
			.setFontSize(48)
			.setFill("#ffffff");
		Phaser.Display.Align.In.Center(juegoNumeroTexto, juegoNumeroBoton);
		this.ganadorCuadro = this.escena.add.image(this.x + (juegoMarco.displayWidth / 2), this.y + 390, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoPremio)
			.setVisible(false)
			.setDepth(manejarDepthMainGame.profundidad4);

		this.signoInterrogacion = this.escena.add.sprite(this.x + 180, this.y + 280, AtlasImagenes.Question3D, AtlasImagenesDetalles[AtlasImagenes.Question3D].callbackObtenerFrame("1"))
			.setDepth(manejarDepthMainGame.profundidad3);

		// this.cuadroGanadorArriba = this.escena.add.image(this.coordenasCuadroGanador[0].x, this.coordenasCuadroGanador[0].y, Imagenes.GanadorArriba)
		// 	.setDepth(this.manejarDepth.CuadroGanador)
		// 	.setOrigin(0, 0)
		// 	.setAlpha(0)
		// 	.setVisible(false)
		// 	.setDisplaySize(79, 83);

		// this.cuadroGanadorMedio = this.escena.add.image(this.coordenasCuadroGanador[1].x, this.coordenasCuadroGanador[1].y, Imagenes.GanadorMedio)
		// 	.setDepth(this.manejarDepth.CuadroGanador)
		// 	.setOrigin(0, 0)
		// 	.setAlpha(0)
		// 	.setVisible(false)
		// 	.setDisplaySize(68, 86);

		// this.cuadroGanadorAbajo = this.escena.add.image(this.coordenasCuadroGanador[2].x, this.coordenasCuadroGanador[2].y, Imagenes.GanadorAbajo)
		// 	.setDepth(this.manejarDepth.CuadroGanador)
		// 	.setOrigin(0, 0)
		// 	.setAlpha(0)
		// 	.setVisible(false)
		// 	.setDisplaySize(83, 87);

		this.estrellasAnimPrimera = this.escena.add.sprite(this.coordenasCuadroGanador[0].x + 40, this.coordenasCuadroGanador[0].y + 40, AtlasImagenes.Estrellas, AtlasImagenesDetalles[AtlasImagenes.Estrellas].callbackObtenerFrame(1))
			.setDepth(manejarDepthMainGame.profundidad3)
			.setDisplaySize(125, 125)
			.setVisible(false);
		this.estrellasAnimSegunda = this.escena.add.sprite(this.coordenasCuadroGanador[1].x + 40, this.coordenasCuadroGanador[1].y + 40, AtlasImagenes.Estrellas, AtlasImagenesDetalles[AtlasImagenes.Estrellas].callbackObtenerFrame(1))
			.setDepth(manejarDepthMainGame.profundidad3)
			.setDisplaySize(125, 125)
			.setVisible(false);
		this.estrellasAnimTercera = this.escena.add.sprite(this.coordenasCuadroGanador[2].x + 40, this.coordenasCuadroGanador[2].y + 40, AtlasImagenes.Estrellas, AtlasImagenesDetalles[AtlasImagenes.Estrellas].callbackObtenerFrame(1))
			.setDepth(manejarDepthMainGame.profundidad3)
			.setDisplaySize(125, 125)
			.setVisible(false);

		this.signoMasPrimeroTexto = this.escena.add.text(this.coordenasCuadroGanador[0].x + 76, this.coordenasCuadroGanador[0].y + 10, "+", { fontFamily: "Gobold-Bold", fontSize: 36, color: "#ffffff" })
			.setDepth(manejarDepthMainGame.profundidad3);

		this.signoMasSegundoTexto = this.escena.add.text(this.coordenasCuadroGanador[1].x + 76, this.coordenasCuadroGanador[1].y + 10, "+", { fontFamily: "Gobold-Bold", fontSize: 36, color: "#ffffff" })
			.setDepth(manejarDepthMainGame.profundidad3);

		this.escena.add.image(this.coordenasCuadroGanador[0].x, this.coordenasCuadroGanador[0].y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoNumeroSorteado)
			.setAlpha(0.5)
			.setDepth(manejarDepthMainGame.profundidad2)
			.setOrigin(0, 0);
		this.escena.add.image(this.coordenasCuadroGanador[1].x, this.coordenasCuadroGanador[1].y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoNumeroSorteado)
			.setAlpha(0.5)
			.setDepth(manejarDepthMainGame.profundidad2)
			.setOrigin(0, 0);
		this.escena.add.image(this.coordenasCuadroGanador[2].x, this.coordenasCuadroGanador[2].y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoNumeroSorteado)
			.setAlpha(0.5)
			.setDepth(manejarDepthMainGame.profundidad2)
			.setOrigin(0, 0);
	}

	setResultadoSorteo(juegoSorteado: JuegoSorteado) {
		this.resultadoJuego = juegoSorteado;
	}

	get faltaVisualizarNumeros() {
		return this.resultadoJuego?.numerosSorteados.some(x => !x.visualizado);
	}

	obtenerCoordenasSiguienteEfecto() {
		for (let index = 0; index < this.resultadoJuego.numerosSorteados.length; index++) {
			if (!this.resultadoJuego.numerosSorteados[index].visualizado) {
				return this.coordenasCuadroGanador[index];
			}
		}
		return null;
	}

	obtenerNumeroSorteado() {
		const numeroSeleccionado = this.resultadoJuego.numerosSorteados.find(x => !x.visualizado);
		return numeroSeleccionado.numero;
	}

	crearEmitterMonedas() {
		const config: Phaser.Types.GameObjects.Particles.ParticleEmitterConfig = {
			frame: AtlasImagenesDetalles[AtlasImagenes.Moneda3D].callbackObtenerFrame("1"),
			lifespan: 1000,
			speed: { min: 300, max: 400 },
			alpha: { start: 1, end: 0 },
			scale: { start: 0.5, end: 0 },
			rotate: { start: 0, end: 360, ease: 'Power2' },
			blendMode: 'ADD',
			frequency: -1
		};
		this.particlesEmitterExplosionMonedas = this.escena.add.particles(null, null, AtlasImagenes.Moneda3D, config)
			.setDepth(manejarDepthMainGame.profundidad7);
	}

	crearEmitterSignoInterrogacion() {
		const config: Phaser.Types.GameObjects.Particles.ParticleEmitterConfig = {
			frame: AtlasImagenesDetalles[AtlasImagenes.Question3D].callbackObtenerFrame("1"),
			lifespan: 1000,
			speed: { min: 300, max: 400 },
			alpha: { start: 1, end: 0 },
			scale: { start: 0.5, end: 0 },
			rotate: { start: 0, end: 360, ease: 'Power2' },
			blendMode: 'ADD',
			frequency: -1
		};
		this.particlesEmitterExplosionSignoInterrogacion = this.escena.add.particles(null, null, AtlasImagenes.Question3D, config)
			.setDepth(manejarDepthMainGame.profundidad7);
	}

	crearEmitterGemas() {
		const config: Phaser.Types.GameObjects.Particles.ParticleEmitterConfig = {
			frame: [AtlasImagenesVarias.Gema1, AtlasImagenesVarias.Gema2, AtlasImagenesVarias.Gema3, AtlasImagenesVarias.Gema4],
			lifespan: 1000,
			speed: { min: 300, max: 400 },
			alpha: { start: 1, end: 0 },
			scale: { start: 0.5, end: 0 },
			rotate: { start: 0, end: 360, ease: 'Power2' },
			blendMode: 'ADD',
			frequency: -1
		};
		this.particlesEmitterExplosionGemas = this.escena.add.particles(null, null, AtlasImagenes.ImagenesVarias, config)
			.setDepth(manejarDepthMainGame.profundidad7);

	}

	setVisualizarNumeroSorteado() {
		//FIXME: mejorar este codigo, PIENSO QUE DEBERIA SER UN CALLBCK Y ENVIAR POR REFERENCIA LOS OBJETOS CORRESPONDIENTES
		const numeroSeleccionado = this.resultadoJuego.numerosSorteados.findIndex(x => !x.visualizado);
		this.resultadoJuego.numerosSorteados[numeroSeleccionado].visualizado = true;
		reproducirSonidoTerminarMostrarNumero(this.escena);
		if (numeroSeleccionado === 0) {
			// this.numeroGanadorArriba = this.escena.add.image(this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y, AtlasImagenes.Bolas, AtlasImagenesDetalles[AtlasImagenes.Bolas].callbackObtenerFrame(this.resultadoJuego.numerosSorteados[numeroSeleccionado].numero))
			this.numeroGanadorPrimeroImagen = this.escena.add.image(this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoNumeroSorteado)
				.setDepth(manejarDepthMainGame.profundidad3)
				.setOrigin(0, 0);
			this.numeroGanadorPrimeroTexto = this.escena.add.text(this.x + 60, this.y + 25, `${this.resultadoJuego.numerosSorteados[numeroSeleccionado].numero}`)
				.setDepth(manejarDepthMainGame.profundidad3)
				.setFontFamily("Gobold-Bold")
				.setFontSize(24)
				.setFill("#ffffff");
			Phaser.Display.Align.In.Center(this.numeroGanadorPrimeroTexto, this.numeroGanadorPrimeroImagen);

			// this.particlesEmitterExplosion.explode(24, this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y);
			this.particlesEmitterExplosionMonedas.explode(12, this.numeroGanadorPrimeroTexto.x, this.numeroGanadorPrimeroTexto.y);
			this.estrellasAnimPrimera.setVisible(true);
			this.estrellasAnimPrimera.anims.play({
				key: AtlasAnimacionesDetalles[AtlasAnimaciones.Estrellas].NombreAnimacion,
				// frameRate: Phaser.Math.Between(6, 20)
				frameRate: 10,
				repeat: 0,
				hideOnComplete: true
			}, true);
		} else if (numeroSeleccionado === 1) {
			// this.numeroGanadorMedio = this.escena.add.image(this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y, AtlasImagenes.Bolas, AtlasImagenesDetalles[AtlasImagenes.Bolas].callbackObtenerFrame(this.resultadoJuego.numerosSorteados[numeroSeleccionado].numero))
			this.numeroGanadorSegundoImagen = this.escena.add.image(this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoNumeroSorteado)
				.setDepth(manejarDepthMainGame.profundidad3)
				.setOrigin(0, 0);
			this.numeroGanadorSegundoTexto = this.escena.add.text(this.x + 60, this.y + 25, `${this.resultadoJuego.numerosSorteados[numeroSeleccionado].numero}`)
				.setDepth(manejarDepthMainGame.profundidad3)
				.setFontFamily("Gobold-Bold")
				.setFontSize(24)
				.setFill("#ffffff");
			Phaser.Display.Align.In.Center(this.numeroGanadorSegundoTexto, this.numeroGanadorSegundoImagen);
			// this.signoMasPrimeroTexto.setVisible(true);
			// this.particlesEmitterExplosion.explode(24, this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y);
			this.particlesEmitterExplosionMonedas.explode(12, this.numeroGanadorSegundoTexto.x, this.numeroGanadorSegundoTexto.y);
			this.estrellasAnimSegunda.setVisible(true);
			this.estrellasAnimSegunda.anims.play({
				key: AtlasAnimacionesDetalles[AtlasAnimaciones.Estrellas].NombreAnimacion,
				// frameRate: Phaser.Math.Between(6, 20)
				frameRate: 10,
				repeat: 0,
				hideOnComplete: true
			}, true);
		} else if (numeroSeleccionado === 2) {
			// this.numeroGanadorAbajo = this.escena.add.image(this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y, AtlasImagenes.Bolas, AtlasImagenesDetalles[AtlasImagenes.Bolas].callbackObtenerFrame(this.resultadoJuego.numerosSorteados[numeroSeleccionado].numero))
			this.numeroGanadorTerceroImagen = this.escena.add.image(this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.JuegoNumeroSorteado)
				.setDepth(manejarDepthMainGame.profundidad3)
				.setOrigin(0, 0);
			this.numeroGanadorTerceroTexto = this.escena.add.text(this.x + 60, this.y + 25, `${this.resultadoJuego.numerosSorteados[numeroSeleccionado].numero}`)
				.setDepth(manejarDepthMainGame.profundidad3)
				.setFontFamily("Gobold-Bold")
				.setFontSize(24)
				.setFill("#ffffff");
			Phaser.Display.Align.In.Center(this.numeroGanadorTerceroTexto, this.numeroGanadorTerceroImagen);
			// this.signoMasSegundoTexto.setVisible(true);
			// this.particlesEmitterExplosion.explode(24, this.coordenasCuadroGanador[numeroSeleccionado].x, this.coordenasCuadroGanador[numeroSeleccionado].y);
			this.particlesEmitterExplosionMonedas.explode(12, this.numeroGanadorTerceroTexto.x, this.numeroGanadorTerceroTexto.y);
			this.estrellasAnimTercera.setVisible(true);
			this.estrellasAnimTercera.anims.play({
				key: AtlasAnimacionesDetalles[AtlasAnimaciones.Estrellas].NombreAnimacion,
				// frameRate: Phaser.Math.Between(6, 20)
				frameRate: 10,
				repeat: 0,
				hideOnComplete: true
			}, true);
			// this.estrellaRoja.anims.stopOnFrame(this.estrellaRoja.anims.currentAnim.frames[0]);
			this.signoInterrogacion.anims.stop();
			this.signoInterrogacion.setVisible(false);
			this.particlesEmitterExplosionSignoInterrogacion.explode(12, this.signoInterrogacion.x, this.signoInterrogacion.y);

			if (this.resultadoJuego.montoGanado > 0) {
				this.mostrarEfectoGanador();
			}

			// this.numeroGanador = this.escena.add.sprite(this.x + 175, this.y + 255, AtlasImagenes.Bolas, AtlasImagenesDetalles[AtlasImagenes.Bolas].callbackObtenerFrame(this.resultadoJuego.sumaNumeros))
			const seleccionGema = {
				1: () => AtlasImagenesVarias.Gema1,
				2: () => AtlasImagenesVarias.Gema2,
				3: () => AtlasImagenesVarias.Gema3,
				4: () => AtlasImagenesVarias.Gema4,
			};
			this.numeroGanadorSprite = this.escena.add.sprite(this.x + 175, this.y + 265, AtlasImagenes.ImagenesVarias , seleccionGema[this.numeroJuego]())
				.setAlpha(0)
				.setScale(0.3)
				.setDepth(manejarDepthMainGame.profundidad3);
			this.numeroGanadorTexto = this.escena.add.text(0, 0, `${this.resultadoJuego.sumaNumeros}`)
				.setDepth(manejarDepthMainGame.profundidad3 + 1)
				.setFontFamily("Gobold-Bold")
				.setFontSize(64)
				.setScale(0.3)
				.setAlpha(0)
				.setFill("#ffffff")
				.setStroke("#000000", 6);
			Phaser.Display.Align.In.Center(this.numeroGanadorTexto, this.numeroGanadorSprite);
			this.escena.tweens.add({
				targets: [this.numeroGanadorTexto, this.numeroGanadorSprite],
				alpha: 1,
				scale: 1,
				duration: 1500,
				onStart: () => {
					//
				},
				onComplete: () => {
					//
				}
			});

		}
	}

	mostrarEfectoGanador() {
		reproducirSonidoWin(this.escena);
		const avance = this.ganadorCuadro.width / 8;
		const inicio = this.ganadorCuadro.x - this.ganadorCuadro.displayWidth / 2;
		this.ganadorCuadro.setAlpha(0).setScale(0.3).setVisible(true);
		this.ganadorTexto = this.escena.add.text(0, 0, `$ ${this.resultadoJuego.montoGanado.toLocaleString('es-cl')}`)
			.setDepth(manejarDepthMainGame.profundidad4 + 1)
			.setFontFamily("Gobold-Bold")
			.setFontSize(36)
			.setScale(0.3)
			.setAlpha(0)
			.setFill("#ffffff")
			.setStroke("#000000", 6);
		Phaser.Display.Align.In.Center(this.ganadorTexto, this.ganadorCuadro);
		// ganadorTexto
		let i = 1;
		this.escena.tweens.add({
			targets: [this.ganadorCuadro, this.ganadorTexto],
			alpha: 1,
			scale: 1,
			angle: 720,
			duration: 1500,
			onUpdate: () => {
				// console.log(i);
				if (i % 50 === 0 && i <= 400) {
					this.particlesEmitterExplosionGemas.explode(15, inicio + ((i / 50) * avance), this.ganadorCuadro.y);
				}
				++i;
			},
			onStart: () => {
				//
			},
			onComplete: () => {
				//
			}
		});
	}

}