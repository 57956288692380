import Phaser from "phaser";
import { AtlasImagenes, AtlasImagenesVarias } from "../Utilidades/Diccionario";

export class LluviaEstrellas {

	constructor(private escena: Phaser.Scene, private depth = 0) { }

	public create(): void {
		// 	frequency: 150,
		// 	// angle: { min: 0, max: 0 },

		// 	lifespan: { min: 1500, max: 2000 },
		// 	alpha: {random: [0.2, 0.6]},
		// 	speedX: {min: -5, max: 5},
		// 	speedY: {min: 300, max: 400},
		// 	blendMode: 'ADD',
		this.escena.add.particles(null, null, AtlasImagenes.ImagenesVarias, {
			frame: AtlasImagenesVarias.LogoPolla,
			x: { min: 0, max: this.escena.cameras.main.width },
			y: { min: 0, max: (this.escena.cameras.main.height / 2) },
			quantity: 1,
			frequency: 500,
			// angle: { min: 0, max: 0 },
			gravityY: 300,
			gravityX: 0,
			// scale: {start: 0.9, end: 0.7},
			// scale: {min: 0.1, max: 0.3},
			// scale: [0.33, 0,99],
			scale: { min: 0.1, max: 0.5 },
			lifespan: { min: 1500, max: 2000 },
			// accelerationY: 40,
			alpha: { min: 0.2, max: 0.6 },
			speedX: { min: -5, max: 5} ,
			speedY: { min: 300, max: 400 },
		})
			.setDepth(this.depth);
		// particulas.setScale(0.2);

	}
}
