import { reproducirSonidoBotones } from "../Utilidades/exportFunction";
import { AtlasBotonesImagenes, AtlasImagenes, AtlasImagenesDetalles, Eventos, Sonidos, TexturasManuales, Variables } from "../Utilidades/Diccionario";

export default class MenuScene extends Phaser.Scene {
	public static Name = "MenuScene";
	private botonMenu: Phaser.GameObjects.Image;
	// private imagenMenu: Phaser.GameObjects.Image;
	private botonMusica: Phaser.GameObjects.Image;
	private botonMusicaEfectos: Phaser.GameObjects.Image;
	private botonConfiguracion: Phaser.GameObjects.Image;
	private botonAyuda: Phaser.GameObjects.Image;
	private cerrandoEscena = false;
	public init() {
		// por implementar
		this.cerrandoEscena = false;
	}

	public preload(): void {
		// por implementar

	}

	public create(): void {
		this.add.image(0, 0, TexturasManuales.FondoTransparente)
			.setOrigin(0, 0)
			.setInteractive()
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (!this.cerrandoEscena) {
					this.cerrarEscena();
				}
			});

		this.crearBotonMenu();
		// this.imagenMenu = this.add.image(0, 0, Imagenes.MenuBarra)
		// 	.setDepth(1)
		// 	.setScale(0.5);
		this.crearBotonMusica();
		this.crearBotonMusicaEfectos();
		this.crearBotonAyuda();
		this.crearBotonConfiguraciones();
		const container = this.add.container(this.cameras.main.width + 50, 150);
		container.add([this.botonMusica, this.botonMusicaEfectos, this.botonAyuda, this.botonConfiguracion]);
		this.add.tween({
			targets: [container],
			// y: 150,
			x: this.cameras.main.width - 200,
			ease: 'Cubic', //'Cubic', 'Elastic', 'Bounce', 'Back'
		});
	}

	crearBotonMenu() {
		this.botonMenu = this.add.image(this.cameras.main.width - 110, 20, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.Menu))
			// .setScale(0.5)
			.setOrigin(0)
			.setDepth(2)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (!this.cerrandoEscena) {
					this.cerrarEscena();
				}
			})
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
	}

	crearBotonMusica() {
		const fnSonidoActivo = () => {
			const sonido = this.sound.get(Sonidos.MusicaFondo);
			return sonido?.isPlaying;
		};

		const musicaVolumen = <number> this.registry.get(Variables.MusicaVolumen);
		this.botonMusica = this.add.image(130, 225, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOn))
			.setDepth(2)
			// .setDisplaySize(45, 45)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_MOVE, () => {
				this.botonMusica.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, () => {
				this.botonMusica.clearTint();
			});

		this.botonMusica.on(Phaser.Input.Events.POINTER_UP, () => {
			const sonido = this.sound.get(Sonidos.MusicaFondo);
			if (!sonido) {
				this.sound.play(Sonidos.MusicaFondo, { loop: true, volume: musicaVolumen / 100 });
				this.botonMusica.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOn));
			}else if (sonido?.isPlaying) {
				this.botonMusica.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOff));
				sonido.stop();
			} else {
				sonido.play({loop: true, volume: musicaVolumen / 100});
				this.botonMusica.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOn));
			}
		});

		if (!fnSonidoActivo()) {
			this.botonMusica.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaOff));
		}
	}

	crearBotonMusicaEfectos() {
		const efectosSonido = <boolean> this.registry.get(Variables.MusicaEfectosActivada);
		this.botonMusicaEfectos = this.add.image(130, 150, AtlasImagenes.Botones, efectosSonido ? AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectos) : AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectosMute))
			.setDepth(2)
			// .setDisplaySize(45, 45)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				const efectosSonidoActivados = <boolean> this.registry.get(Variables.MusicaEfectosActivada);
				this.registry.set(Variables.MusicaEfectosActivada, !efectosSonidoActivados);
				reproducirSonidoBotones(this);
				if (!efectosSonidoActivados) {
					this.botonMusicaEfectos.clearTint();
					this.botonMusicaEfectos.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectos));
				} else {
					this.botonMusicaEfectos.setTint(0xff504d);
					this.botonMusicaEfectos.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MusicaEfectosMute));
				}
			});

		if (!efectosSonido) {
			this.botonMusicaEfectos.setTint(0xff504d);
		}
		// this.botonMusicaEfectos.on(Phaser.Input.Events.POINTER_UP, () => {
		// 	this.EfectosSonidoActivados = !this.EfectosSonidoActivados;
		// 	if (this.EfectosSonidoActivados) {
		// 		this.botonMusicaEfectos.clearTint();
		// 		this.botonMusicaEfectos.setTexture('MusicaEfectos');
		// 	} else {
		// 		this.botonMusicaEfectos.setTint(0xff504d);
		// 		this.botonMusicaEfectos.setTexture('MusicaEfectosMute');
		// 	}
		// });

		this.botonMusicaEfectos.on(Phaser.Input.Events.POINTER_MOVE, function () {
			this.setTint(0x00ff00);
		});
		this.botonMusicaEfectos.on(Phaser.Input.Events.POINTER_OUT, () => {
			// const efectosSonido = <boolean> this.registry.get(Variables.MusicaEfectosActivada);
			if (<boolean> this.registry.get(Variables.MusicaEfectosActivada)) {
				this.botonMusicaEfectos.clearTint();
			} else {
				this.botonMusicaEfectos.setTint(0xff504d);
			}
		});
	}

	crearBotonAyuda() {
		this.botonAyuda = this.add.image(130, 75, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MenuInformacion))
			.setDepth(2)
			// .setDisplaySize(45, 45)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				this.cerrarEscena(true);
			})
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
	}

	crearBotonConfiguraciones() {
		this.botonConfiguracion = this.add.image(130, 0, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MenuConfiguracion))
			.setDepth(2)
			// .setDisplaySize(45, 45)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				this.cerrarEscena(false, true);
			})
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
	}

	cerrarEscena(enviarEventoVerAyuda = false, enviarEventoVerConfiguraciones = false) {
		this.cerrandoEscena = true;
		reproducirSonidoBotones(this);
		const container = this.add.container(this.cameras.main.width - 200, 150);
		container.add([this.botonMusica, this.botonMusicaEfectos, this.botonAyuda, this.botonConfiguracion]);
		this.add.tween({
			targets: [container],
			// y: -300,
			x: this.cameras.main.width + 50,
			ease: 'Cubic', //'Cubic', 'Elastic', 'Bounce', 'Back'
			onComplete: () => {
				this.scene.stop(MenuScene.Name);
				if (enviarEventoVerAyuda) {
					this.registry.events.emit(Eventos.MenuAbrirAyuda, this.registry.get(Variables.EscenaLaunchMenu)); // el 2do parametro no se si se utilizara en algun momento
				} else if (enviarEventoVerConfiguraciones) {
					this.registry.events.emit(Eventos.MenuAbrirConfiguraciones, this.registry.get(Variables.EscenaLaunchMenu)); // el 2do parametro no se si se utilizara en algun momento
				} else {
					this.scene.resume(this.registry.get(Variables.EscenaLaunchMenu));
				}
			}
		});
	}
}
