export enum Variables {
	ParametrosEntrada = 'VariableParametrosEntrada',
	MusicaEfectosActivada = 'VariableMusicaEfectosActivada',
	MusicaEfectosVolumen = 'VariableMusicaEfectosVolumen',
	MusicaVolumen = 'VariableMusicaVolumen',
	Saldo = 'VariableSaldo',
	IdiomaSeleccionado = 'VariableIdiomaSeleccionado',
	EscenaLaunchMenu = 'VariableEscenaLaunchMenu',
	IdSorteoActual = 'VariableIdSorteoActual',
	MostrarAyudaInicial = 'VariableMostrarAyudaInicial',
	FechaHoraInicio = 'VariableFechaHoraInicio',
	EscenaActual = 'VariableEscenaActual', // Esta variable se utiliza para controlar el giro de un dispositivo movil (vertical/horizontal), asi conoceremos que escena se debe pausar
	BackendErrorBalance = "VariableBackendErrorBalance",
	BackendErrorLanzar = "VariableBackendErrorLanzar",
	MensajeParametros = "VariableMensajeParametros",
}

export enum Eventos {
	MenuAbrirAyuda = "EventoMenuAbrirAyuda",
	MenuAbrirConfiguraciones = "EventoMenuAbrirConfiguraciones",
	CambiarIdioma = "EventoCambiarIdioma", // No se esta utilizando
	CambiosSaldoCliente = "EventoCambiosSaldoCliente",
	PulsarMoneda = "EventoPulsarMoneda",
}

export enum TexturasManuales {
	FondoTransparente = "TexturaFondoTransparente",
	AyudaComoJugar = "TexturaAyudaComoJugar",
	AyudaJuegos = "TexturaAyudaLineas",
	AyudaGanarBonus = "TexturasAyudaGanarBonus",
}

export enum Imagenes {
	Background = 'background',
	BackgroundModal = "background-modal",
	Ayuda1 = "ayuda-1",
	Ayuda2 = "ayuda-2",
	Ayuda3 = "ayuda-3",
	CajaMonedas = 'caja-monedas',
}

// export enum SpriteImagenes {
// 	Estrellas = 'SpriteSheetEstrellas',
// 	Humo = 'SpriteSheetHumo',
// }

// export const SpriteDetalles: {[Alias: string]: {NombreArchivo: string, AnchoFrame: number, AltoFrame: number, FrameFinal?: number}} = {
// 	'SpriteSheetEstrellas': { NombreArchivo: 'estrellas.png', AltoFrame: 300, AnchoFrame: 290 },
// 	'SpriteSheetHumo': { NombreArchivo: 'Smoke.png', AltoFrame: 125, AnchoFrame: 125, FrameFinal: 20 },
// };

export enum AtlasImagenes {
	Botones = 'AtlasBotones',
	Moneda3D = 'AtlasMoneda3D',
	Question3D = 'AtlasQuestion3D',
	Estrellas = 'AtlasEstrellas',
	ImagenesVarias = 'AtlasImagenesVarias',
}

export const AtlasImagenesDetalles: {[Alias: string]: {NombreArchivoImagen: string, NombreArchivoAtlas: string, prefijoFrame: string, callbackObtenerFrame: (numeroFrame: number | string) => string }} = {
	'AtlasBotones': { NombreArchivoImagen: 'botones.png', NombreArchivoAtlas: 'botones_atlas.json', prefijoFrame: '', callbackObtenerFrame: (numeroFrame: string) => `${numeroFrame}` },
	'AtlasMoneda3D': { NombreArchivoImagen: 'moneda-3d.png', NombreArchivoAtlas: 'moneda-3d_atlas.json', prefijoFrame: '', callbackObtenerFrame: (numeroFrame: string) => `moneda-${numeroFrame.padStart(2, '0')}` },
	'AtlasEstrellas': { NombreArchivoImagen: 'estrellas.png', NombreArchivoAtlas: 'estrellas_atlas.json', prefijoFrame: 'estrellas', callbackObtenerFrame: (numeroEstrella: number) => `estrellas-${numeroEstrella.toString().padStart(2, '0') }` },
	'AtlasQuestion3D': { NombreArchivoImagen: 'question-3d.png', NombreArchivoAtlas: 'question-3d_atlas.json', prefijoFrame: '', callbackObtenerFrame: (numeroFrame: string) => `question-${numeroFrame.padStart(2, '0')}` },
	'AtlasImagenesVarias': { NombreArchivoImagen: 'imagenes.png', NombreArchivoAtlas: 'imagenes_atlas.json', prefijoFrame: '', callbackObtenerFrame: (numeroFrame: string) => `${numeroFrame}` },
};

export enum AtlasBotonesImagenes {
	BotonAnterior = "boton-anterior",
	BotonCerrar = 'boton-cerrar',
	BotonGrande = 'boton-grande',
	BotonSiguiente = "boton-siguiente",
	BotonJugar = 'boton-jugar',
	BotonJugarDisabled = 'boton-jugar-disabled',
	MenuConfiguracion = 'configuracion',
	FullScreen = "full-screen",
	Home = "home",
	MenuInformacion = 'informacion',
	Menu = "menu",
	MusicaEfectosMute = 'musica-efectos-off',
	MusicaEfectos = 'musica-efectos-on',
	MusicaOff = 'musica-off',
	MusicaOn = 'musica-on',
	SeleccionCircularMas = "seleccion-circular-mas",
	SeleccionCircularMenos = "seleccion-circular-menos",
	SeleccionCircularMasDisable = "seleccion-circular-mas-disable",
	SeleccionCircularMenosDisable = "seleccion-circular-menos-disable",
	BotonGrandeDisable = 'boton-grande-disable',
	HomeDisable = "home-disable",
	MenuDisable = "menu-disable",
}

export enum AtlasImagenesVarias {
	Gema1 = 'gema-1',
	Gema2 = 'gema-2',
	Gema3 = 'gema-3',
	Gema4 = 'gema-4',
	JuegoMarco = 'juego-marco',
	JuegoNumero = 'juego-numero',
	JuegoNumeroSorteado = 'juego-numero-sorteado',
	JuegoPremio = 'juego-premio',
	FuegoAmarillo = 'fuego-amarillo',
	LogoJuego = 'logo-juego',
	LogoPolla = 'logo-polla',
	Polla = 'polla',
	Saldo = "saldo",
	Tiempo = "tiempo",
	Burbuja = "burbuja",
	Estrella = 'estrella',
	Lluvia = 'rain',
	ValorJugada = 'valor-jugada',
	NumeroNoSorteado = "numero-no-sorteado",
	NumeroSorteado = "numero-sorteado",
}

export enum AtlasAnimaciones {
	Moneda3D = 'AtlasAnimMoneda3D',
	Question3D = 'AtlasAnimQuestion3D',
	Estrellas = 'AtlasAnimEstrellas',
}

export const AtlasAnimacionesDetalles: {[Alias: string]: {NombreArchivoAtlas: string, NombreAnimacion: string }} = {
	'AtlasAnimMoneda3D': { NombreArchivoAtlas: 'moneda-3d_anim.json', NombreAnimacion: 'rotacion-moneda-3d' },
	'AtlasAnimEstrellas': { NombreArchivoAtlas: 'estrellas_anim.json', NombreAnimacion: 'anim-estrellas' },
	'AtlasAnimQuestion3D': { NombreArchivoAtlas: 'question-3d_anim.json', NombreAnimacion: 'rotacion-question-3d' },
};

export enum Sonidos {
	ApretarBoton = 'SonidoApretarBoton',
	CambioEscena = 'SonidoCambioEscena',
	MusicaFondo = 'SonidoMusicaFondo',
	Spin = 'SonidoSpin',
	Win = 'SonidoWin',
	NuevoSorteo = 'SonidoNuevoSorteo',
	MostrarNumero = 'SonidoMostrarNumero',
}

export const SonidosDetalles: {[Alias: string]: {NombreArchivo: string}} = {
	'SonidoApretarBoton': { NombreArchivo: 'Sonidos/ApretarBoton.wav' },
	'SonidoCambioEscena': { NombreArchivo: 'Sonidos/CambioEscena.wav' },
	'SonidoMusicaFondo': { NombreArchivo: 'Sonidos/musica-fondo.mp3' },
	'SonidoSpin': { NombreArchivo: 'Sonidos/spin.mp3' },
	'SonidoWin': { NombreArchivo: 'Sonidos/win.mp3' },
	'SonidoNuevoSorteo': { NombreArchivo: 'Sonidos/nuevo-sorteo.mp3' },
	'SonidoMostrarNumero': { NombreArchivo: 'Sonidos/mostrar-numero.mp3' },
};

export const manejarDepthMainGame = {
	/** Shader Fuegos Artificiales */
	profundidad0: 0,

	/** BackGround */
	profundidad1: 10,

	/*** Efectos lluvia, particulas logo polla, burbujas, Marco Monedas (MM) */
	profundidad2: 20,

	/*** Logo, Boton Jugar, Imagenes Footer, Monedas en MM, Numeros en MM, Marco Juego (MJ), Numero en MJ, Gema en MJ, Signo '+' en MJ, Menus (Home, FullScreen y Menu)*/
	profundidad3: 30,

	/** Numero Juego en MJ (Cuadro Rojo), Premio Cuadro Verde en MJ*/
	profundidad4: 40,
	profundidad5: 50,
	profundidad6: 60,

	/** Todos los Efectos */
	profundidad7: 70,
}

export enum ListaEstadoJuego {
	Libre = 0,
	MostrandoIndividual = 1,
	MostrandoTodos = 2,
	DisponibleParaJugar = 3,
	EsperandoRespuesta = 4,
}
