import { AtlasBotonesImagenes, AtlasImagenes, AtlasImagenesDetalles, Imagenes, TexturasManuales, Variables } from "../Utilidades/Diccionario";
import { reproducirSonidoCambioEscena } from "../Utilidades/exportFunction";
import { MensajeParametros } from "../Utilidades/Intefaces";

export default class MensajePopupReintentarScene extends Phaser.Scene {
	public static Name = "MensajePopupReintentarScene";

	private fondoImagen: Phaser.GameObjects.Image;
	private textoTitulo: Phaser.GameObjects.Text;
	private textoDetalle: Phaser.GameObjects.Text;
	private botonReintentar: Phaser.GameObjects.Image;
	private botonReintentarTexto: Phaser.GameObjects.Text;

	private flagOcupado: boolean;
	private parametros: MensajeParametros;

	public init() {
		this.flagOcupado = false;
	}

	public preload(): void {
		// por implementar
	}

	public create(): void {
		this.parametros = this.registry.get(Variables.MensajeParametros);
		this.add.image(0, 0, TexturasManuales.FondoTransparente)
			.setOrigin(0, 0);

		this.fondoImagen = this.add.image(0, 0, Imagenes.BackgroundModal)
			.setDisplaySize(600, 300)
			.setOrigin(0, 0);

		this.fondoImagen.setPosition(this.cameras.main.centerX - (this.fondoImagen.displayWidth / 2), this.cameras.main.centerY - (this.fondoImagen.displayHeight / 2));
		this.crearTextos();
		this.crearBoton();
	}

	private crearTextos() {
		const inicioY = this.fondoImagen.y + 10;
		this.textoTitulo = this.add.text(this.fondoImagen.x + 20, inicioY, this.parametros.titulo || '')
			.setFontFamily("Gobold-Bold")
			.setFontSize(30)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');

		this.textoDetalle = this.add.text(this.fondoImagen.x + 20, inicioY + 70, this.parametros.detalle || '')
			.setFontFamily("Gobold-Bold")
			.setFontSize(24)
			// .setFontStyle('bold')
			.setStroke('#c6830c', 4)
			.setFill('#ffffff');
	}

	private crearBoton() {
		this.botonReintentar = this.add.image(this.fondoImagen.x + (this.fondoImagen.displayWidth / 2), this.fondoImagen.y + 250, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonGrande))
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, async () => {
				if (!this.flagOcupado) {
					this.flagOcupado = true;
					this.botonReintentar.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonGrandeDisable));
					this.botonReintentar.clearTint();
					reproducirSonidoCambioEscena(this);
					await this.parametros.callbackReintento();
					this.flagOcupado = false;
					this.botonReintentar.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonGrande));
				}
			})
			.on(Phaser.Input.Events.POINTER_MOVE, () => {
				if (!this.flagOcupado) {
					this.botonReintentar.setTint(0x00ff00);
				}
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});

		this.botonReintentarTexto = this.add.text(0, 0, "Reintentar", { color: "#ffffff"  })
			.setOrigin(0.5, 0)
			.setFontFamily("Gobold-Bold")
			.setFontSize(36)
			.setFill("#185532");
		Phaser.Display.Align.In.Center(this.botonReintentarTexto, this.botonReintentar);
	}
}
