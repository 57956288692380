import Phaser from "phaser";
import { AtlasImagenes, AtlasImagenesVarias } from "../Utilidades/Diccionario";

export class LluviaGotas {
	private relatedScene: Phaser.Scene;
	// public x: Phaser.Types.GameObjects.Particles.EmitterOpOnEmitType;
	// public y: Phaser.Types.GameObjects.Particles.EmitterOpOnEmitType;
	// public quantity: Phaser.Types.GameObjects.Particles.EmitterOpOnEmitType;
	// public lifespan: Phaser.Types.GameObjects.Particles.EmitterOpOnEmitType;
	// public speedX: Phaser.Types.GameObjects.Particles.EmitterOpOnEmitType;
	// public speedY: Phaser.Types.GameObjects.Particles.EmitterOpOnEmitType;

	private configuracion: Phaser.Types.GameObjects.Particles.ParticleEmitterConfig;

	constructor(scene: Phaser.Scene, config: ConfiguracionLluvia, private depth = 0) {

		this.relatedScene = scene;
		// this.x = {min: 0, max: this.relatedScene.cameras.main.width}
		this.configuracion = {
			frame: AtlasImagenesVarias.Lluvia,
			gravityY: config.vertical ? 100 : 0,
			gravityX: !config.vertical ? 100 : 0,
			scale: {start: 0.4, end: 0.2},
			accelerationY: config.vertical ? {random: [1, 5]} : undefined,
			accelerationX: !config.vertical ? {random: [1, 5]} : undefined,
			blendMode: 'ADD',
			alpha: {random: [0.2, 0.99]},
			x: {min: config.x.min || 0, max: config.x.max || this.relatedScene.cameras.main.width},
			y: {min: config.y.min || 0, max: config.y.max || this.relatedScene.cameras.main.height},
			quantity: config.cantidad,
			lifespan: config.lifeSpan,
			speedX: config.speedX ? {min: config.speedX.min || -5, max: config.speedX.max || 5} : {min: -5, max: 5},
			speedY: config.speedY ? {min: config.speedY.min || 10, max: config.speedY.max || 50} : {min: 10, max: 50},
		};
	}

	public create(): void {
		this.relatedScene.add.particles(null, null, AtlasImagenes.ImagenesVarias, this.configuracion).setDepth(this.depth);

		// particulas.createEmitter({
		// 	x: this.x,
		// 	y: this.y,
		// 	// frame: 0,
		// 	quantity: this.quantity,
		// 	frequency: 5,
		// 	// angle: { min: 0, max: 0 },
		// 	gravityY: 0,
		// 	gravityX: 100,
		// 	scale: {start: 0.4, end: 0.2},
		// 	//scale: {min: 0.1, max: 0.5},
		// 	lifespan: this.lifespan,

		// 	// accelerationY: {random: [1, 5]},
		// 	accelerationX: {random: [1, 5]},
		// 	blendMode: 'ADD',
		// 	alpha: {random: [0.2, 0.99]},
		// 	speedX: this.speedX,
		// 	speedY: this.speedY
		// });
	}
}

interface ConfiguracionLluvia {
	vertical: boolean;
	x: {min: number, max: number};
	y: {min: number, max: number};
	cantidad: number;
	lifeSpan: number;
	speedX?: {min: number, max: number};
	speedY?: {min: number, max: number};
}
