import { LluviaEstrellas } from "../Components/LluviaEstrellas";
import { LluviaGotas } from "../Components/LluviaGotas";
import { AtlasBotonesImagenes, AtlasImagenes, AtlasImagenesDetalles, AtlasImagenesVarias, Eventos, Imagenes, ListaEstadoJuego, manejarDepthMainGame, Variables } from "../Utilidades/Diccionario";
import { esDispositivoiOS, fragmentShaderFuegosArtificiales, reproducirSonidoBotones, reproducirSonidoCambioEscena, reproducirSonidoNuevoSorteo } from "../Utilidades/exportFunction";
import { IObjeto3D, MensajeParametros, ParametrosEntrada, ResultadosSorteo } from "../Utilidades/Intefaces";
import { ObtenerTextoMultiIdioma, TextosAplicacion } from "../Utilidades/MultiIdioma";
import ErrorParametrosScene from "./ErrorParametrosScene";
import FooterScene from "./FooterScene";
import MenuScene from "./MenuScene";
import MensajePopupReintentarScene from "./MensajePopupReintentarScene";
import { obtenerBalance, obtenerSorteo } from "../Utilidades/Conexion";
import { JuegoComponent } from "../Components/JuegoComponent";
import { MonedaComponent } from "../Components/MonedaComponent";
import { EfectoGanadorComponent } from "../Components/EfectoGanadorComponent";

export default class PrincipalScene extends Phaser.Scene {
	public static Name = "PrincipalScene";
	private botonConfiguracion: Phaser.GameObjects.Image;
	private botonJugar: Phaser.GameObjects.Image;
	private botonMostrarTodo: Phaser.GameObjects.Image;
	private textoBotonMostrarTodo: Phaser.GameObjects.Text;

	private textoBotonJugar: Phaser.GameObjects.Text;
	private flagEstadoJuego: ListaEstadoJuego;
	public botonScreen: Phaser.GameObjects.Image;
	public botonHome: Phaser.GameObjects.Image;

	private lluviaGotasRectangulo: LluviaGotas;

	private cantidadBurbujas = 15;
	private listaBurbujas: Phaser.GameObjects.Image[];
	private listaMonedas: MonedaComponent[];
	private listaJuego: JuegoComponent[];
	private listaObjetos3D: IObjeto3D[];

	private resultadosSorteo: ResultadosSorteo;
	private efectoGanador: EfectoGanadorComponent;

	public init() {
		this.scene.launch(FooterScene.Name);
		this.listaBurbujas = [];
		this.listaJuego = [];
		this.listaMonedas = [];
		this.listaObjetos3D = [];
		this.lluviaGotasRectangulo = new LluviaGotas(this,
			{
				x: { min: 0, max: this.cameras.main.displayWidth },
				y: { min: 100, max: this.cameras.main.displayHeight },
				lifeSpan: 600,
				speedY: {min: 10, max: 50},
				cantidad: 1,
				vertical: true
			}, manejarDepthMainGame.profundidad2);
		this.flagEstadoJuego = ListaEstadoJuego.DisponibleParaJugar;
	}

	public preload(): void {
		// por implementar
	}

	public create(): void {
		this.registry.set(Variables.EscenaActual, PrincipalScene.Name);
		this.cameras.main.fadeIn(1000, 0, 0, 0);
		const baseShader = new Phaser.Display.BaseShader('BufferShader2', fragmentShaderFuegosArtificiales(5, 7));
		this.add.shader(baseShader, 0, 0, this.cameras.main.displayWidth, this.cameras.main.displayHeight).setOrigin(0, 0).setDepth(manejarDepthMainGame.profundidad0);
		this.add.image(0, 0, Imagenes.Background).setOrigin(0, 0).setAlpha(0.9).setDepth(manejarDepthMainGame.profundidad1);
		const logo = this.add.image(this.cameras.main.displayWidth - 465, 100, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.LogoJuego)
			.setOrigin(0, 0)
			.setAlpha(0.9)
			.setDepth(manejarDepthMainGame.profundidad3);

		this.crearCajaMonedas();
		this.crearBotonMenu();

		this.crearBotonJugar();
		this.crearBotonMostrarTodo();
		this.crearBotonScreen();
		this.crearBotonHome();
		this.crearCajaValorJugada();

		const estrellas = new LluviaEstrellas(this, manejarDepthMainGame.profundidad2);
		estrellas.create();

		this.listaJuego.push(new JuegoComponent(this, 100, 30, 1));
		this.listaJuego.push(new JuegoComponent(this, 470, 30, 2));
		this.listaJuego.push(new JuegoComponent(this, 840, 30, 3));
		this.listaJuego.push(new JuegoComponent(this, 1210, 30, 4));
		this.listaObjetos3D.push(...this.listaJuego);
		this.listaMonedas.push(new MonedaComponent(this, 210, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre ));
		this.listaMonedas.push(new MonedaComponent(this, 380, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 550, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 720, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 890, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 1060, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 1230, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 1400, 675, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));

		this.listaMonedas.push(new MonedaComponent(this, 295, 825, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 465, 825, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 635, 825, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 805, 825, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 975, 825, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 1145, 825, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaMonedas.push(new MonedaComponent(this, 1315, 825, () => this.flagEstadoJuego === ListaEstadoJuego.Libre));
		this.listaObjetos3D.push(...this.listaMonedas);
		// const parametrosEntrada = <ParametrosEntrada> this.registry.get(Variables.ParametrosEntrada);
		// const errorBalance = <boolean> this.registry.get(Variables.BackendErrorBalance);
		// const callbackLlamarMostrarModal = () => {
		// 	setTimeout(() => {
		// 		this.mostrarModalErrorParametros();
		// 	}, 1100);
		// };
		// if (!parametrosEntrada) {
		// 	callbackLlamarMostrarModal();
		// } else if (!parametrosEntrada.channel || !parametrosEntrada.sessionToken || !parametrosEntrada.mode) {
		// 	callbackLlamarMostrarModal();
		// } else if (!['DEMO', 'NORMAL'].includes(parametrosEntrada.mode)) {
		// 	callbackLlamarMostrarModal();
		// } else if (errorBalance) {
		// 	this.mostrarModalErrorObtenerBalance();
		// } else {
		setTimeout(() => {
			// window.parent.postMessage("splashLoaded", "*");
			this.mostrarModalInformacionInicial();
		}, 1100);
		// }

		this.lluviaGotasRectangulo.create();

		for(let i=0; i<this.cantidadBurbujas; i++){
			const xBooble = Phaser.Math.Between(1, this.cameras.main.width);
			const yBooble = Phaser.Math.Between(600, this.cameras.main.height);

			const booble = this.add.image(xBooble, yBooble, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.Burbuja);
			booble.setData('velocidad', 0.2 + Phaser.Math.FloatBetween(0.1, 1.5));
			booble.alpha = 0.9;

			booble.setScale( 0.2 + Phaser.Math.FloatBetween(0.1, 0.5));
			this.listaBurbujas.push(booble);
		}

		this.registry.events.removeListener(Eventos.PulsarMoneda);
		this.registry.events.on(Eventos.PulsarMoneda, (monedaPulsada: MonedaComponent) => {
			this.ejecutarEfectorPulsarMoneda(monedaPulsada);
		});

		const particles = this.add.particles(null, null, AtlasImagenes.ImagenesVarias, {
			frame: AtlasImagenesVarias.FuegoAmarillo,
			x: this.cameras.main.displayWidth - 200,
			y: 175,
			lifespan: 800,
			angle: { start: 360, end: 0, steps: 32 },
			speed: 200,
			quantity: 1,
			scale: { start: 0.8, end: 0 },
			frequency: 40,
			blendMode: 'ADD',
		}).setDepth(manejarDepthMainGame.profundidad7);
		this.time.delayedCall(2500, () => {
			// particlesEmitter.stop();
			particles.destroy();
		});

		this.crearParticulasBrillo(logo);
		this.efectoGanador = new EfectoGanadorComponent(this);
	}

	public update(): void {
		// this.tilesprite.tilePositionX += 0.1;
		// this.tilesprite.tilePositionY += 0.6;
		for(let i = 0; i < this.cantidadBurbujas; i++){
			const burbuja = this.listaBurbujas[i];
			burbuja.y -= burbuja.getData('velocidad')
			if (burbuja.y < -50) {
				burbuja.setScale( 0.2 + Phaser.Math.FloatBetween(0.1, 0.5));
				burbuja.y = Phaser.Math.Between(600, this.cameras.main.height);
				burbuja.x = Phaser.Math.Between(1, this.cameras.main.width);
			}
		}
	}

	crearBotonMenu() {
		this.botonConfiguracion = this.add.image(this.cameras.main.width - 110, 20, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.Menu))
			// .setScale(0.5)
			.setOrigin(0)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setInteractive({ useHandCursor: true })
			// .setPipeline('Light2D')
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if ([ListaEstadoJuego.Libre, ListaEstadoJuego.DisponibleParaJugar].includes(this.flagEstadoJuego)) {
					this.botonConfiguracion.clearTint();
					reproducirSonidoBotones(this);
					this.registry.set(Variables.EscenaLaunchMenu, PrincipalScene.Name);
					this.registry.set(Variables.EscenaActual, MenuScene.Name);
					this.scene.launch(MenuScene.Name);
					this.scene.pause(PrincipalScene.Name);
				}
			})
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
	}

	crearBotonJugar() {
		this.botonJugar = this.add.image(this.cameras.main.displayWidth - 250, this.cameras.main.centerY + 110, AtlasImagenes.Botones, AtlasBotonesImagenes.BotonJugar)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (this.flagEstadoJuego === ListaEstadoJuego.DisponibleParaJugar) {
					this.efectoGanador.ocultar();
					this.botonJugar.clearTint();
					this.flagEstadoJuego = ListaEstadoJuego.EsperandoRespuesta;
					this.botonJugar.clearTint();
					this.actualizarImagenesBotones();
					reproducirSonidoNuevoSorteo(this);
					for (const iterator of this.listaObjetos3D) {
						iterator.girarStartObjeto();
					}

					obtenerSorteo(100, null, null)
						.then(sorteo => {
							console.log(sorteo);
							this.resultadosSorteo = sorteo;
							this.flagEstadoJuego = ListaEstadoJuego.Libre;
							this.actualizarImagenesBotones();
							for (const iterator of this.listaMonedas) {
								iterator.habilitarPulsacion();
							}

							for (let index = 0; index < sorteo.juegos.length; index++) {
								this.listaJuego[index].setResultadoSorteo(sorteo.juegos[index]);
							}
						});
				}
				// this.registry.set(Variables.NumerosSeleccionados, this.listaBolasSeleccionadasIndices.map(x => x + 1).sort((a, b) => a - b));
				// this.cameras.main.fadeOut(500, 0, 0, 0);
				// this.cameras.main.once(Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE, (cam, effect) => {
				// 	this.scene.start(MainGame.Name);
				// });
				// }
			})
			.on(Phaser.Input.Events.POINTER_MOVE, () => {
				if (this.flagEstadoJuego === ListaEstadoJuego.DisponibleParaJugar) {
					this.botonJugar.setTint(0x00ff00);
				}
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
		// this.botonComenzar.input.cursor = 'not-allowed';

		this.textoBotonJugar = this.add.text(0, 0, "Juega")
			.setOrigin(0.5, 0)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setFontFamily("Gobold-Bold")
			.setFontSize(64)
			.setFill("#ffffff");
		Phaser.Display.Align.In.Center(this.textoBotonJugar, this.botonJugar);
	}

	crearBotonMostrarTodo() {
		this.botonMostrarTodo = this.add.image(this.cameras.main.displayWidth - 250, this.cameras.main.centerY + 110, AtlasImagenes.Botones, AtlasBotonesImagenes.BotonJugar)
			.setVisible(false)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (this.flagEstadoJuego === ListaEstadoJuego.Libre) {
					this.flagEstadoJuego = ListaEstadoJuego.MostrandoTodos;
					this.botonMostrarTodo.clearTint();
					// const baseShader = new Phaser.Display.BaseShader('BufferShader2', fragmentShaderRGBShiftField());
					// const shader = this.add.shader(baseShader, 0, 0, this.cameras.main.displayWidth, this.cameras.main.displayHeight).setOrigin(0, 0).setDepth(manejarDepthMainGame.profundidad0);
					this.actualizarImagenesBotones();
					// const listaSinVisualizar = this.resultadosSorteo.juegos.flatMap(x => x.numerosSorteados).filter(x => !x.visualizado);
					const listaSinVisualizar = Phaser.Utils.Array.Shuffle(this.listaMonedas.filter(x => !x.MonedaPulsada));
					Phaser.Utils.Array.RemoveRandomElement(listaSinVisualizar);
					Phaser.Utils.Array.RemoveRandomElement(listaSinVisualizar);
					Phaser.Utils.Array.RemoveRandomElement(listaSinVisualizar);
					// const listaSinVisualizar = this.listaMonedas
					// 	.map((x, y) => ({
					// 		MonedaPulsada: x.MonedaPulsada,
					// 		Posicion: y,
					// 	}))
					// 	.filter(x => !x.MonedaPulsada);
					const tiempo = this.time.addEvent({
						delay: 1100,
						startAt: 100,
						repeat: listaSinVisualizar.length - 1,
						callback: () => {
							this.registry.events.emit(Eventos.PulsarMoneda, listaSinVisualizar[tiempo.getRepeatCount()]);
							// console.log({
							// 	getOverallProgress: tiempo.getOverallProgress(),
							// 	getOverallRemaining: tiempo.getOverallRemaining(),
							// 	getElapsedSeconds: tiempo.getElapsedSeconds(),
							// 	getOverallRemainingSeconds: tiempo.getOverallRemainingSeconds(),
							// 	getProgress: tiempo.getProgress(),
							// 	getRemaining: tiempo.getRemaining(),
							// 	getRemainingSeconds: tiempo.getRemainingSeconds(),
							// 	getRepeatCount: tiempo.getRepeatCount(),
							// });
							if (tiempo.getOverallProgress() === 1) {
								// shader.destroy();
							}
						},
					});
					reproducirSonidoCambioEscena(this);

				}
			})
			.on(Phaser.Input.Events.POINTER_MOVE, () => {
				if (this.flagEstadoJuego === ListaEstadoJuego.Libre) {
					this.botonMostrarTodo.setTint(0x00ff00);
				}
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
		// this.botonComenzar.input.cursor = 'not-allowed';

		this.textoBotonMostrarTodo = this.add.text(0, 0, "Mostrar\nTodo")
			.setDepth(manejarDepthMainGame.profundidad3)
			.setAlign("center")
			.setVisible(false)
			.setOrigin(0.5, 0)
			.setFontFamily("Gobold-Bold")
			.setFontSize(60)
			.setFill("#ffffff");
		Phaser.Display.Align.In.Center(this.textoBotonMostrarTodo, this.botonMostrarTodo, 0, 10);
	}

	cambioTextosIdioma() {
		this.textoBotonJugar.setText(ObtenerTextoMultiIdioma(this.registry.get(Variables.IdiomaSeleccionado), TextosAplicacion.SeleccionarScene_BotonComenzar));
	}

	crearBotonScreen() {
		if (esDispositivoiOS()) {
			return;
		}
		this.botonScreen = this.add.image(0, 0, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.FullScreen))
			.setOrigin(0)
			.setDepth(manejarDepthMainGame.profundidad3)
			// .setDisplaySize(36, 36)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				// this.scale.startFullscreen();
				this.scale.toggleFullscreen();
			})
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
		this.botonScreen.setPosition(this.cameras.main.width - 180, 20);
		// this.scale.on('enterfullscreen', () => {
		// 	console.log("object");
		// });

	}

	crearBotonHome() {
		this.botonHome = this.add.image(0, 0, AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.Home))
			.setOrigin(0)
			// .setDisplaySize(36, 36)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setInteractive({ useHandCursor: true })
			.on(Phaser.Input.Events.POINTER_UP, () => {
				if (this.flagEstadoJuego === ListaEstadoJuego.Libre) {
					window.parent.postMessage("goToLobby", "*");
					const param = <ParametrosEntrada> this.registry.get(Variables.ParametrosEntrada);
					if (param.lobbyUrl) {
						window.location.href = param.lobbyUrl;
					}
				}
			})
			.on(Phaser.Input.Events.POINTER_MOVE, function () {
				this.setTint(0x00ff00);
			})
			.on(Phaser.Input.Events.POINTER_OUT, function () {
				this.clearTint();
			});
		this.botonHome.setPosition(this.cameras.main.width - 250, 20);
	}

	crearCajaValorJugada() {
		const valorJugadaImagen = this.add.image(this.cameras.main.displayWidth - 250, this.cameras.main.centerY + 375 , AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.ValorJugada)
			.setDepth(manejarDepthMainGame.profundidad3);
		const textoValorJugada = this.add.text(0, 0, "Valor Jugada", { fontFamily: "Gobold-Bold", fontSize: "20px", color: "#ffffff" })
			.setDepth(manejarDepthMainGame.profundidad3);
		Phaser.Display.Align.In.Center(textoValorJugada, valorJugadaImagen, 0, -40);
		const textoValor = this.add.text(0, 0, "$ 400", { fontFamily: "Gobold-Bold", fontSize: "48px", color: "#ffffff" })
			.setDepth(manejarDepthMainGame.profundidad3);
		Phaser.Display.Align.In.Center(textoValor, valorJugadaImagen, 0, 10);
	}

	mostrarModalInformacionInicial() {
		const mostrarInformacion = <boolean> this.registry.get(Variables.MostrarAyudaInicial);
		if (mostrarInformacion) {
			this.registry.set(Variables.MostrarAyudaInicial, false);
			this.registry.set(Variables.EscenaLaunchMenu, PrincipalScene.Name);
			// this.scene.launch(MenuScene.Name);
			this.scene.pause(PrincipalScene.Name);
			this.registry.events.emit(Eventos.MenuAbrirAyuda, this.registry.get(Variables.EscenaLaunchMenu)); // el 2do parametro no se si se utilizara en algun momento
		}
	}

	mostrarModalErrorParametros() {
		this.scene.pause(PrincipalScene.Name);
		this.scene.launch(ErrorParametrosScene.Name);
	}

	mostrarModalErrorObtenerBalance() {
		setTimeout(() => {
			this.registry.set(Variables.MensajeParametros
				, <MensajeParametros> {
					titulo: "Error inesperado",
					detalle: `Ha sucedido un error al obtener su balance,
favor presione el botón para reintentar.`,
					callbackReintento: async () => {
						this.registry.set(Variables.BackendErrorBalance, false);
						const retorno = await obtenerBalance(this.registry.get(Variables.ParametrosEntrada), () => this.registry.set(Variables.BackendErrorBalance, true));
						this.registry.set(Variables.Saldo, retorno?.balance || 0);
						this.registry.events.emit(Eventos.CambiosSaldoCliente);
						if (!this.registry.get(Variables.BackendErrorBalance)) {
							this.scene.resume(PrincipalScene.Name);
							this.scene.stop(MensajePopupReintentarScene.Name);
						}
					}
				}
			);
			this.scene.pause(PrincipalScene.Name);
			this.scene.launch(MensajePopupReintentarScene.Name);
		}, 1100);
	}

	actualizarImagenesBotones() {

		if (this.flagEstadoJuego === ListaEstadoJuego.DisponibleParaJugar) {
			this.botonJugar.setTexture(AtlasImagenes.Botones, AtlasBotonesImagenes.BotonJugar);
			this.botonJugar.input.cursor = 'pointer';
			this.botonJugar.setVisible(true);
			this.textoBotonJugar.setVisible(true);
			this.botonMostrarTodo.setVisible(false);
			this.textoBotonMostrarTodo.setVisible(false);

		} else if (this.flagEstadoJuego === ListaEstadoJuego.EsperandoRespuesta) {
			this.botonJugar.setTexture(AtlasImagenes.Botones, AtlasBotonesImagenes.BotonJugarDisabled);
			this.botonJugar.input.cursor = 'not-allowed';
		} else {
			this.botonJugar.setVisible(false);
			this.textoBotonJugar.setVisible(false);
			this.botonMostrarTodo.setVisible(true);
			this.textoBotonMostrarTodo.setVisible(true);
		}

		if ([ListaEstadoJuego.EsperandoRespuesta, ListaEstadoJuego.MostrandoIndividual, ListaEstadoJuego.MostrandoTodos].includes( this.flagEstadoJuego )) {
			this.botonHome.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.HomeDisable)).setOrigin(0);
			this.botonConfiguracion.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.MenuDisable)).setOrigin(0);
			this.botonHome.input.cursor = 'not-allowed';
			this.botonConfiguracion.input.cursor = 'not-allowed';
			this.botonMostrarTodo.setTexture(AtlasImagenes.Botones, AtlasBotonesImagenes.BotonJugarDisabled);
			this.botonMostrarTodo.input.cursor = 'not-allowed';
			return;
		}

		this.botonMostrarTodo.setTexture(AtlasImagenes.Botones, AtlasBotonesImagenes.BotonJugar);
		this.botonMostrarTodo.input.cursor = 'pointer';
		this.botonHome.input.cursor = 'pointer';
		this.botonHome.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.Home)).setOrigin(0);
		this.botonConfiguracion.input.cursor = 'pointer';
		this.botonConfiguracion.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.Menu)).setOrigin(0);

		// this.botonLanzar.setTexture(AtlasImagenes.Botones, AtlasImagenesDetalles[AtlasImagenes.Botones].callbackObtenerFrame(AtlasBotonesImagenes.BotonLanzar));
		// this.botonLanzar.input.cursor = 'pointer';
	}

	ejecutarEfectorPulsarMoneda(monedaPulsada: MonedaComponent) {
		if (this.flagEstadoJuego === ListaEstadoJuego.Libre) {
			this.flagEstadoJuego = ListaEstadoJuego.MostrandoIndividual;
		}
		const juegoEfecto = this.listaJuego.find(juego => juego.faltaVisualizarNumeros)
		const coordenasEfecto: {x: number, y: number} = juegoEfecto.obtenerCoordenasSiguienteEfecto();
		this.actualizarImagenesBotones();
		const listaAnimaciones: Phaser.Types.Tweens.TweenBuilderConfig[] = [
			{
				targets: monedaPulsada.obtenerSprite(),
				duration: 750,
				displayWidth: 25,
				displayHeight: 25,
				y: coordenasEfecto.y,
				x: coordenasEfecto.x,
				onStart: () => {
					//
				},
				onComplete: () => {
					monedaPulsada.terminarPulsacion(juegoEfecto.obtenerNumeroSorteado());
					juegoEfecto.setVisualizarNumeroSorteado();
				}
			}
		];

		// const cuadroGanador = juegoEfecto.obtenerCuadroGanador();
		// listaAnimaciones.push({
		// 	targets: cuadroGanador,
		// 	duration: 1000,
		// 	offset: -750,
		// 	alpha: 1,
		// 	onStart: () => {
		// 		cuadroGanador.setAlpha(0);
		// 		cuadroGanador.setVisible(true);
		// 	},
		// });

		this.tweens.chain({
			tweens: [
				...listaAnimaciones
			],
			onComplete: () => {
				if (this.flagEstadoJuego === ListaEstadoJuego.MostrandoIndividual) {
					this.flagEstadoJuego = ListaEstadoJuego.Libre;
				}
				if (this.resultadosSorteo.juegos.flatMap(x => x.numerosSorteados).every(x => x.visualizado)) {
					this.flagEstadoJuego = ListaEstadoJuego.DisponibleParaJugar;
					this.mostrarPerdedores();

					if (this.resultadosSorteo.totalGanado > 0) {
						this.efectoGanador.mostrar(this.resultadosSorteo.totalGanado);
					}
				}
				this.actualizarImagenesBotones();
			},
		});
	}

	crearCajaMonedas() {
		const cajaMonedas = this.add.image(50, 475, Imagenes.CajaMonedas).setOrigin(0, 0).setAlpha(0.9).setDepth(manejarDepthMainGame.profundidad2);

		const texto1 = this.add.text(cajaMonedas.x + 100, cajaMonedas.y + 15, `Selecciona    monedas por juego`)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setFontFamily("Gobold-Bold")
			.setFontSize(36)
			.setFill("#000000");
		// this.add.text(texto1.x + texto1.displayWidth , cajaMonedas.y + 15, ` ¡Consigue un total de 7, 14, 21 y 28 para ganar!`)
		this.add.text(texto1.x + texto1.displayWidth , cajaMonedas.y + 15, ` ¡Si en un juego sumas 7, 14, 21 o 28 ya ganaste!`)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setFontFamily("Gobold-Bold")
			.setFontSize(36)
			.setFill("#ffffff");

		this.add.text(cajaMonedas.x + 285, cajaMonedas.y + 10, `3`)
			.setDepth(manejarDepthMainGame.profundidad3)
			.setFontFamily("Gobold-Bold")
			.setFontSize(44)
			.setFill("#ffffff");

		// const valorApuesta = this.add.text(0, 0, ` VALOR DE LA JUGADA $ 400`)
		// 	.setDepth(manejarDepthMainGame.profundidad3)
		// 	.setFontFamily("Gobold-Bold")
		// 	// .setFontStyle("bold")
		// 	.setFontSize(36)
		// 	.setFill("#ffffff");
		// Phaser.Display.Align.In.BottomCenter(valorApuesta, cajaMonedas, 0, -15);
		const valorApuesta = this.add.text(0, 0, `Los Juegos 1, 2, 3 y 4 son independientes entre sí.`, { fontFamily: "Gobold-Bold", fontSize: 32, color: "#ffffff" })
			.setDepth(manejarDepthMainGame.profundidad3);
		Phaser.Display.Align.In.BottomCenter(valorApuesta, cajaMonedas, 0, -20);
	}

	crearParticulasBrillo(logo: Phaser.GameObjects.Image) {
		const textures = this.textures;
		const origin = logo.getTopLeft();
		const logoSource = {
			getRandomPoint: function (vec) {
				let pixel;
				let x, y: number;
				do {
					x = Phaser.Math.Between(0, logo.displayWidth - 1);
					y = Phaser.Math.Between(0, logo.displayHeight - 1);
					pixel = textures.getPixel(x, y, AtlasImagenes.ImagenesVarias, AtlasImagenesVarias.LogoJuego);
				} while (pixel.alpha < 255);

				return vec.setTo(x + origin.x, y + origin.y);
			}
		};
		const configParticulas: Phaser.Types.GameObjects.Particles.ParticleEmitterConfig = {
			frame: AtlasImagenesVarias.FuegoAmarillo,
			x: 0,
			y: 0,
			lifespan: 1000,
			gravityY: 10,
			scale: { start: 0, end: 0.25, ease: 'Quad.easeOut' },
			alpha: { start: 1, end: 0, ease: 'Quad.easeIn' },
			blendMode: 'ADD',
			emitZone: { type: 'random', source: logoSource }
		};
		this.add.particles(null, null, AtlasImagenes.ImagenesVarias, configParticulas).setDepth(manejarDepthMainGame.profundidad7);
	}

	mostrarPerdedores() {
		this.listaMonedas.filter(x => !x.MonedaPulsada).forEach((x, y) => x.verNumeroPerdedor(this.resultadosSorteo.numerosSinSortear[y]));
	}
}
